
const routes :Array<CookiesRouter> = [
  {
    path: '/Reports',
    name: 'Reports',
    meta: {
      Access: 'Reports_View' as AccessFeature
    },
    component: () => import('@/Apps/Reports/views/Reports.vue'),
    children: [
      {
        path: '/Reports/Inventory',
        name: 'Inventory Reports',
        component: () => import('@/Apps/Inventory/views/Reports/InventoryReports.vue'),
        meta: {
          Access: 'Inventory_View' as AccessFeature
        },
        children: [
          {
            path: '/Reports/Inventory/Exports',
            name: 'Export Reports',
            component: () => import('@/Apps/Inventory/views/Reports/ExportsReports.vue'),
            meta: {
              Access: 'Inventory_Transfers_Read' as AccessFeature,
              ReportsDateSelector: true
            }
          },
          {
            path: '/Reports/Inventory/Imports',
            name: 'Imports Reports',
            component: () => import('@/Apps/Inventory/views/Reports/ImportsReports.vue'),
            meta: {
              Access: 'Inventory_Transfers_Read' as AccessFeature,
              ReportsDateSelector: true
            }
          },
          {
            path: '/Reports/Inventory/ExternalImports',
            name: 'External Imports Reports',
            component: () => import('@/Apps/Inventory/views/Reports/ExternalImportsReports.vue'),
            meta: {
              Access: 'Inventory_Transfers_Read' as AccessFeature,
              ReportsDateSelector: true
            }
          },
          {
            path: '/Reports/Inventory/Items',
            name: 'Items Reports',
            component: () => import('@/Apps/Inventory/views/Reports/InventoryItemsReports.vue'),
            meta: {
              Access: 'Inventory_Transfers_Read' as AccessFeature
            }
          }
        ]
      },
      {
        path: '/Reports/Sales',
        name: 'Sales Reports',
        component: () => import('@/Apps/Sales/views/Reports/SalesReports.vue'),
        meta: {
          Access: 'Sales_View' as AccessFeature
        },
        children: [
          {
            path: '/Reports/Sales/Orders',
            name: 'Sales Orders Reports',
            component: () => import('@/Apps/Sales/views/Reports/SalesOrdersReports.vue'),
            meta: {
              Access: 'Sales_View' as AccessFeature,
              ReportsDateSelector: true
            }
          },
          {
            path: '/Reports/Sales/receivables',
            name: 'Sales Orders receivables Reports',
            component: () => import('@/Apps/Sales/views/Reports/SalesCustomersReports.vue'),
            meta: {
              Access: 'Sales_View' as AccessFeature,
              ReportsDateSelector: true
            }
          }
        ]
      },
      {
        path: '/Reports/Purchase',
        name: 'Purchase Reports',
        component: () => import('@/Apps/Purchase/views/Reports/PurchaseReports.vue'),
        meta: {
          Access: 'Purchase_View' as AccessFeature
        },
        children: [
          {
            path: '/Reports/Purchase/Orders',
            name: 'Purchase Orders Reports',
            component: () => import('@/Apps/Purchase/views/Reports/PurchaseOrdersReport.vue'),
            meta: {
              Access: 'Purchase_View' as AccessFeature,
              ReportsDateSelector: true
            }
          },
          {
            path: '/Reports/Purchase/receivables',
            name: 'Purchase Orders receivables Reports',
            component: () => import('@/Apps/Purchase/views/Reports/PurchaseVendorsReport.vue'),
            meta: {
              Access: 'Purchase_View' as AccessFeature,
              ReportsDateSelector: true
            }
          }
        ]
      },
      {
        path: '/Reports/Finance',
        name: 'Finance Reports',
        component: () => import('@/Apps/Finance/views/Reports/FinanceReports.vue'),
        meta: {
          Access: 'Finance_View' as AccessFeature
        },
        children: [
          {
            path: '/Reports/Finance/Transactions',
            name: 'Finance Transactions Reports',
            component: () => import('@/Apps/Finance/views/Reports/TransactionsReports.vue'),
            meta: {
              Access: 'Finance_View' as AccessFeature,
              ReportsDateSelector: true
            }
          }
        ]
      }
    ]
  }
]

export const ReportsRoutes = routes
