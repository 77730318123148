

import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {
    if (this.Tabs.length > 0) {
      this.$router.push(this.Tabs[0].Link as any)
    }
  },
  data () {
    return {}
  },
  computed: {
    Name () {
      return this.Translate('Security')
    },
    Tabs () {
      var Tabs = [
        { Name: this.Translate('Finance'), Link: '/Reports/Finance/Transactions', Access: this.Access('Finance_View') }
      ] as Array<CookiesTabs>
      return Tabs.filter(tab => {
        return tab.Access
      })
    }
  },
  methods: {},
  watch: {}
})
