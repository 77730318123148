import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "BigLoading"
}
const _hoisted_2 = {
  class: "P-Abs D-Flex BG2 P_5em",
  style: {"z-index":"555","inset-inline-end":"2em","gap":"0.5em","border-radius":"0 0 var(--rad3) var(--rad3)"}
}
const _hoisted_3 = {
  key: 2,
  class: "TreeView"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesLoading = _resolveComponent("CookiesLoading")!
  const _component_CookiesIcon = _resolveComponent("CookiesIcon")!
  const _component_CookiesFunctionalTable = _resolveComponent("CookiesFunctionalTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.Loading.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createVNode(_component_CookiesLoading)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CookiesIcon, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.MEMWrite('V_Items_ViewType', 'Tile'))),
        ToolTip: _ctx.Translate('Tiles View'),
        class: "SQR2em V-Auto Pointer",
        ID: ('TileView' as IconsIDs)
      }, null, 8, ["ToolTip", "ID"]),
      _createVNode(_component_CookiesIcon, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.MEMWrite('V_Items_ViewType', 'Grid'))),
        ToolTip: _ctx.Translate('Grid View'),
        class: "SQR2em V-Auto Pointer",
        ID: ('GridView' as IconsIDs)
      }, null, 8, ["ToolTip", "ID"])
    ]),
    (_ctx.MEMRead('V_Items_ViewType') === 'Tile')
      ? (_openBlock(), _createBlock(_component_CookiesFunctionalTable, {
          key: 1,
          onShowLocations: _ctx.ShowLocations,
          onViewItemHistory: _ctx.ViewItemHistory,
          onSwapStatus: _ctx.SwapStatus,
          onEditItem: _ctx.EditItem,
          onDeleteItem: _ctx.DeleteItem,
          Loading: _ctx.Loading,
          CookiesData: _ctx.FilteredData,
          TableSettings: _ctx.TableSettings
        }, null, 8, ["onShowLocations", "onViewItemHistory", "onSwapStatus", "onEditItem", "onDeleteItem", "Loading", "CookiesData", "TableSettings"]))
      : _createCommentVNode("", true),
    (_ctx.MEMRead('V_Items_ViewType') === 'Tile')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true)
  ], 64))
}