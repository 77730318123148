
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
// import router from './Apps/ALL/router/index'
const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  components: {},
  data () {
    return {
      Configed: false
    }
  },
  created () {
    this.DefaultConfig = this.$store.state.CookiesConfig
    var LocalConfig = this.LocalRead('CookiesDefaultConfig')
    if (LocalConfig !== null) {
      this.$store.state.CookiesConfig = LocalConfig
      this.Configed = true
    }
    // this.ReadConfig().then((Configed) => {
    //   this.Configed = Configed as boolean
    // })
    this.ReadSystemConfig()
  },
  beforeMount () {
    this.TimeBeforeMounted()
    this.CookiesAuthorization()
  },
  mounted () {
    const favicon = document.getElementById('FavIcon') as HTMLLinkElement
    favicon.href = this.$store.state.CookiesConfig.FavIco
    setTimeout(() => {
      window.scrollTo(0, 1)
    }, 300)
  },
  methods: {
    ToolTipSence () {
      this.$store.state.ToolTipElement = document.querySelector('[ToolTip]:hover')
    },
    ReadSystemConfig () {
      this.ReadConfig().then((Configed) => {
        this.Configed = Configed as boolean
        if (!Configed) {
          var _this = this
          setTimeout(() => {
            _this.$store.state.AutoSignIn = true
            _this.ReadSystemConfig()
            _this.CookiesAuthorization()
          }, 10000)
        } else {
          console.log(this.$store.state.SystemManagment.CurrentSystems)
        }
      })
    }
  },
  computed: {
    TabHeaderShadower () {
      return this.$store.getters.CurrentTheme === 'light' ? 'rgba(0, 0, 0, 0.20)' : 'rgba(0, 0, 0, 0.17)'
    },
    Maintenance () {
      return this.$store.state.CookiesConfig.MaintenanceMode === 'true'
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "5735f61a": (_ctx.$store.getters.Direction),
  "215d08e7": (_ctx.Theme.color3),
  "ecd10d1e": (_ctx.Theme.colorBold),
  "116a54c8": (_ctx.$store.getters.DefaultTextAlign),
  "4f7bde96": (_ctx.Theme.UserColors.main),
  "4b18ff20": (_ctx.Theme.UserColors.second),
  "3065d9eb": (_ctx.Theme.UserColors.third),
  "764cb3d4": (_ctx.Theme.UserColors.fourth),
  "215d08e5": (_ctx.Theme.color1),
  "215d08e6": (_ctx.Theme.color2),
  "6f4a2835": (_ctx.Theme.BG1),
  "6f4a2836": (_ctx.Theme.BG2),
  "6f4a2837": (_ctx.Theme.BG3),
  "6f4a2838": (_ctx.Theme.BG4),
  "6f4a2839": (_ctx.Theme.BG5),
  "6f4a283a": (_ctx.Theme.BG6),
  "6f4a283b": (_ctx.Theme.BG7),
  "6f4a283c": (_ctx.Theme.BG8),
  "2f4ba83f": (_ctx.Theme.BG1Fixed),
  "310080de": (_ctx.Theme.BG2Fixed),
  "32b5597d": (_ctx.Theme.BG3Fixed),
  "346a321c": (_ctx.Theme.BG4Fixed),
  "361f0abb": (_ctx.Theme.BG5Fixed),
  "37d3e35a": (_ctx.Theme.BG6Fixed),
  "3988bbf9": (_ctx.Theme.BG7Fixed),
  "3b3d9498": (_ctx.Theme.BG8Fixed),
  "25ffaa10": (_ctx.Theme.InputColor),
  "eb06c740": (_ctx.Theme.UserColors.Dimmed),
  "46e8ef68": (_ctx.Theme.UserColors.Gradient),
  "05a7af7a": (_ctx.Theme.Shade0),
  "05a7af7b": (_ctx.Theme.Shade1),
  "05a7af7c": (_ctx.Theme.Shade2),
  "05a7af7d": (_ctx.Theme.Shade3),
  "05a7af7e": (_ctx.Theme.Shade4),
  "341107f6": (_ctx.Theme.BackBlur),
  "3017a20d": (_ctx.Theme.UserColors.main_25),
  "1c8bc56f": (_ctx.Theme.UserColors.third_50),
  "0c72b498": (_ctx.Theme.Light3),
  "aee72f9a": (_ctx.DirectionStyler.borderLeft),
  "6956ff70": (_ctx.DirectionStyler.borderRight),
  "76b3f1bd": (_ctx.TabHeaderShadower),
  "42c1e98e": (_ctx.CurrentBackgroundColor),
  "6a225e60": (_ctx.DirectionStyler.first),
  "13eb5f14": (_ctx.DirectionStyler.last),
  "f5013dd6": (`url(${require("@/assets/elements/HorizontalEdgesMask.svg")})`)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__