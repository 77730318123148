
import { Waypoint } from 'vue-waypoint'
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    TableSettings: { default: () => {}, type: Object as PropType<CookiesTable> },
    CookiesData: { default: [], Type: Array },
    Search: { default: '' },
    Loading: { default: false, Type: Boolean },
    Saving: { default: false, Type: Boolean },
    Saved: { default: undefined, Type: Boolean },
    ErrorData: { default: false, Type: Boolean }
  },
  components: {
    Waypoint
  },
  data () {
    return {
      PrintStatus: {},
      SortElement: {} as SortElement,
      TablePredefinedValue: undefined as any,
      Grapping: null as any,
      GrappedItem: {} as any,
      GroppedClone: null as any,
      LoadedElements: 50,
      SortingInfo: {
        InitialPosition: 0,
        DestinationPostition: 0,
        RangeStart: 0,
        RangeEnd: 0,
        Direction: 1
      },
      DefaultSortElement: {
        Direction: -1,
        Path: ['Date']
      } as SortElement
    }
  },
  created () {
    this.TablePredefinedValue = this.MEMRead(this.TableSettings.CookiesID)
    const _this = this
    window.addEventListener('mouseup', function () {
      _this.StopGrapping()
    })
    window.addEventListener('mouseleave', function () {
      _this.StopGrapping()
    })
    window.addEventListener('touchend', function () {
      _this.StopGrapping()
    })
  },
  mounted () {
    this.ApplyTableSettings()
    this.IntiateTableValues()
  },
  computed: {
    SearchedData () {
      var Filtered = this.SortedData
      this.TableSettings.Fields.forEach(field => {
        if (field.IsSearchable && this.MEMRead('PrintTableFieldSearch_' + field.Name) && this.MEMRead('PrintTableFieldSearch_' + field.Name) !== '') {
          Filtered = Filtered.filter(element => {
            return this.CookiesSearcher(element, this.MEMRead('PrintTableFieldSearch_' + field.Name), { Exclusive: true, CustomAttribute: field.Value })
          })
        }
      })
      return Filtered
    },
    VisibleData () {
      if (this.TableSettings.InfiniteScroller) {
        return this.CookiesFilter(this.SearchedData, {}, this.Search).slice(0, this.LoadedElements)
      } else {
        return this.CookiesFilter(this.SearchedData, {}, this.Search)
      }
    },
    SortIndexEdited () {
      var DataBeforeEditing = this.DeepCloner(this.IDReplaced)
      if (this.TableSettings.Sorter && this.Grapping !== null) {
        var EditedSortedIndex = DataBeforeEditing
        EditedSortedIndex = EditedSortedIndex.map((element :any) => {
          if (this.TableSettings.Sorter) {
            var ElementDefaultSortIndex = this.DeepFetcher(element, this.TableSettings.Sorter)
            // this.Console('ElementDefaultSortIndex', 'color: red')
            // this.Console(ElementDefaultSortIndex)
            // this.Console(this.SortingInfo.RangeStart)
            // this.Console(this.SortingInfo.RangeEnd)
            // this.Console(this.SortingInfo.Direction)
            if (ElementDefaultSortIndex < this.SortingInfo.RangeStart || ElementDefaultSortIndex > this.SortingInfo.RangeEnd) {
              // this.Console('a', 'color: yellow')
            } else if (ElementDefaultSortIndex === this.SortingInfo.InitialPosition) {
              this.DeepChanger(element, 'SorterIndex', this.SortingInfo.DestinationPostition)
              // this.Console('b', 'color: yellow')
            } else {
              // this.Console('c', 'color: yellow')
              this.DeepChanger(element, 'SorterIndex', ElementDefaultSortIndex - this.SortingInfo.Direction)
            }
            return element
          }
        })
        this.Console('SorterIndex', 'color: orange; font-size: 2em;')
        this.Console(DataBeforeEditing)
        this.Console(EditedSortedIndex)
        return EditedSortedIndex
      } else {
        return DataBeforeEditing
      }
    },
    SortedData () {
      var DataBeforeSorting = this.DeepCloner(this.SortIndexEdited)
      var AfterSorting = []
      if (this.TableSettings.Sorter && this.Grapping !== null) {
        AfterSorting = this.GeneralSort(DataBeforeSorting, 'SorterIndex', this.SortElement.Direction)
      } else {
        AfterSorting = this.GeneralSort(DataBeforeSorting, this.SortElement.Path, this.SortElement.Direction)
      }
      return AfterSorting
    },
    IDReplaced () {
      var IDElemenst :Array<CookiesTableField> = []
      this.TableSettings.Fields.forEach(field => {
        if (field.IsID && field.Type === 'normal') {
          IDElemenst.push(field)
        }
      })
      if (IDElemenst.length === 0) {
        return this.IndexedData
      }
      return this.IndexedData.map(element => {
        IDElemenst.forEach(field => {
          var Value = this.DeepFetcher(element, field.Value)
          if (typeof Value === 'string' && Value.indexOf('#') === 0) return
          this.DeepChanger(element, field.Value, '#' + Value)
        })
        return element
      })
    },
    IndexedData () {
      return this.CookiesData.map((element :any, index :number) => {
        element.OriginalIndex = index
        if (this.TableSettings.Sorter) {
          element.SorterIndex = this.DeepFetcher(element, this.TableSettings.Sorter)
        }
        return element
      })
    },
    TableValue () {
      var ChangingFields :Array<{ChangePath: ObjectPath, Name: string, ResID: ObjectPath}> = []
      var Selector = false
      var SelectorRes :ObjectPath
      this.TableSettings.Fields.forEach(field => {
        if (field.Editable || field.Type === 'selector' || field.Type === 'numberInput') {
          if (field.Editable && field.Res_ID) {
            ChangingFields.push({
              ChangePath: field.Value,
              Name: field.Name,
              ResID: field.Res_ID
            })
          } else if (field.Type === 'selector') {
            Selector = true
            SelectorRes = field.Res_ID ? field.Res_ID : ''
          } else if (field.Type === 'numberInput' && field.Res_ID) {
            ChangingFields.push({
              ChangePath: field.Value,
              Name: field.Name,
              ResID: field.Res_ID
            })
          }
        }
      })
      var SelectedData :Array<any>
      var ClonedData :Array<any> = this.DeepCloner(this.SearchedData)
      if (Selector) {
        SelectedData = ClonedData.filter(element => {
          return this.MEMRead(this.TableSettings.CookiesID + '_selector_' + this.DeepFetcher(element, SelectorRes))
        })
      } else {
        SelectedData = ClonedData
      }
      return SelectedData.map(element => {
        var ModifiedElement = element
        ChangingFields.forEach(changer => {
          this.DeepChanger(ModifiedElement, changer.ChangePath, this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + changer.Name + '_' + this.DeepFetcher(element, changer.ResID)))
        })
        return ModifiedElement
      })
    },
    SelectorField () {
      return this.TableSettings.Fields.find(field => {
        return field.Type === 'selector'
      })
    },
    FilteredFields () {
      return this.TableSettings.Fields.filter(field => {
        return field.Visible !== false
      })
    }
  },
  methods: {
    DynamicCalculator (item :any, Field :any, DynamicObject :'DynamicVisiblity' | 'DynamicClass' | 'CellDynamicClass') {
      if (Field[DynamicObject] === undefined) return true
      var Result = false
      const DV = Field[DynamicObject]
      const itemValue = this.DeepFetcher(item, DV.ValuePath)
      if (!itemValue) return Result
      switch (DV.Operator) {
        case 'EQUAL LOWERCASE':
          if (itemValue === DV.FixedValue || itemValue.toLowerCase() === DV.FixedValue.toLowerCase()) Result = true
          break
        case 'NOT EQUAL LOWERCASE':
          if (itemValue !== DV.FixedValue && itemValue.toLowerCase() !== DV.FixedValue.toLowerCase()) Result = true
          break
        case 'EQUAL':
          if (itemValue === DV.FixedValue) Result = true
          break
        case 'NOT EQUAL':
          if (itemValue !== DV.FixedValue) Result = true
          break
        case 'LARGER':
          if (itemValue > DV.FixedValue) Result = true
          break
        case 'SMALLER':
          if (itemValue < DV.FixedValue) Result = true
          break
      }
      console.log(Result)
      return Result
    },
    ChangePrintState (Name :string) {
      this.DeepChanger(this.PrintStatus, Name, !this.DeepFetcher(this.PrintStatus, Name))
      this.LocalSave(this.TableSettings.CookiesID + '_TablePrint', this.PrintStatus)
    },
    TotalOF (field :CookiesTableField) {
      var Value = field?.Value
      var Name = field?.Name
      var TOTAL = 0
      var DynamicTotal = {} as any
      this.SearchedData.forEach(element => {
        var NewValue = this.DeepFetcher(element, Value) !== undefined ? this.DeepFetcher(element, Value) : 0
        if (field.DynamicUnit) {
          var key = this.DeepFetcher(element, field.DynamicUnit)
          if (!DynamicTotal[key]) DynamicTotal[key] = 0
          DynamicTotal[key] += NewValue
        }
        TOTAL += NewValue
      })
      var VisibleTotal = '' as any
      if (field.DynamicUnit) {
        Object.keys(DynamicTotal).forEach((key, index) => {
          var Prefix = index > 0 ? '<br>' : ''
          VisibleTotal += Prefix + this.DecimalFixer(DynamicTotal[key]) + ' ' + (key !== 'null' ? key : '')
        })
      } else {
        VisibleTotal = this.DecimalFixer(TOTAL)
      }
      this.MEMWrite((this.TableSettings.CookiesID + '_Total_' + Name), VisibleTotal)
      return VisibleTotal
    },
    ApplyTableSettings () {
      if (!this.TableSettings || !this.TableSettings.CookiesID) {
        return
      }
      if (this.TableSettings.ClientMemory) {
        var TableSort = this.LocalRead(this.TableSettings.CookiesID + '_TableSort')
        var TablePrint = this.LocalRead(this.TableSettings.CookiesID + '_TablePrint')
        if (TableSort !== null) {
          this.SortElement = TableSort
        } else if (this.TableSettings.DefaultSort !== undefined) {
          this.SortElement = this.TableSettings.DefaultSort
        } else {
          this.SortElement = this.DefaultSortElement
        }
        if (TablePrint !== null && this.TableSettings.PrintControls) {
          this.PrintStatus = TablePrint
        } else {
          this.TableSettings.Fields.forEach((element) => {
            this.DeepChanger(this.PrintStatus, element.Name, false)
          })
        }
      } else {
        if (this.TableSettings.DefaultSort !== undefined) {
          this.SortElement = this.TableSettings.DefaultSort
        } else {
          this.SortElement = this.DefaultSortElement
        }
        this.TableSettings.Fields.forEach((element) => {
          this.DeepChanger(this.PrintStatus, element.Name, false)
        })
      }
    },
    RowSelectCheck (element :any) {
      if (this.SelectorField && this.SelectorField.Res_ID) {
        return this.MEMRead(this.TableSettings.CookiesID + '_selector_' + this.DeepFetcher(element, this.SelectorField.Res_ID))
      } else {
        return true
      }
    },
    TextChanged (Res :string, MyEvent :Event, field :CookiesTableField, element :any) {
      const Elem = MyEvent.target as Element
      var Original = this.MEMRead(Res)
      var New = Elem?.innerHTML.trim()
      console.log(Elem)
      if (Original !== New) {
        try {
          this.MEMWrite(Res, Elem?.innerHTML.trim())
          this.DeepChanger(element, field.Value, this.MEMRead(Res))
          this.$emit('FieldEdited', element)
        } catch (error) {
        }
      }
    },
    RowFinder (element :any) :string {
      var HighlitedRows :Array<RowHighliterObject> = this.MEMRead(this.TableSettings.RowsHighliterRes)
      if (HighlitedRows && HighlitedRows.length > 0) {
        var ElementRow = HighlitedRows.find(row => {
          return this.DeepFetcher(element, row.Path) === row.Value
        })
        if (ElementRow) {
          return ElementRow.HighlightColor || ''
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    IntiateTableValues () {
      const SelectorField = this.TableSettings.Fields.find(field => {
        return field.Type === 'selector'
      })
      if (SelectorField && SelectorField.Res_ID) {
        const ResID = SelectorField.Res_ID
        if (!this.TablePredefinedValue) {
          return
        }
        this.TablePredefinedValue.forEach((element :any) => {
          var ElementID = this.DeepFetcher(element, ResID)
          this.MEMWrite(this.TableSettings.CookiesID + '_selector_' + ElementID, true)
        })
      }
    },
    Grapped (event :any, element: any, type :'Touch' | 'Mouse') {
      this.Grapping = this.$refs['Row_' + this.DeepFetcher(element, 'OriginalIndex')][0]
      this.GrappedItem = element
      if (this.Grapping !== null && this.TableSettings.Sorter) {
        // this.Grapping.style.opacity = 0.2
        if (type === 'Mouse') {
          this.IsMoving(event)
        } else {
          this.IsTouching(event)
        }
      }
    },
    IsTouching (event :any) {
      if (this.Grapping !== null && this.TableSettings.Sorter) {
        event.preventDefault()
        // this.Console(document.getElementById(this.TableSettings.CookiesID)?.getBoundingClientRect())
        // this.Console(event.clientY)
        var Table = document.getElementById(this.TableSettings.CookiesID)
        var HeeaderSize = Table?.getElementsByTagName('tr')[1].getBoundingClientRect().height
        var RowsSize = Table?.getElementsByTagName('tr')[2].getBoundingClientRect().height
        var TableTop = Table?.getBoundingClientRect().top
        if (TableTop && HeeaderSize) {
          TableTop = TableTop + HeeaderSize
        }
        var MouseTop = event.changedTouches[0].clientY
        var Top = MouseTop
        if (TableTop && RowsSize) {
          Top = Math.min(Math.max(0, MouseTop - TableTop), RowsSize * this.SearchedData.length)
        }
        var NewPlace = Top
        if (Top && RowsSize) {
          NewPlace = Math.min(Math.max(1, Math.ceil(Top / RowsSize)), this.SearchedData.length)
          this.Console(NewPlace)
        }
        // handle items
        this.SortingInfo.InitialPosition = this.DeepFetcher(this.GrappedItem, this.TableSettings.Sorter)
        this.SortingInfo.DestinationPostition = NewPlace
        this.SortingInfo.RangeStart = this.SortingInfo.InitialPosition
        this.SortingInfo.RangeEnd = this.SortingInfo.DestinationPostition
        this.SortingInfo.Direction = 1
        if (this.SortingInfo.InitialPosition > this.SortingInfo.DestinationPostition) {
          this.SortingInfo.RangeStart = this.SortingInfo.DestinationPostition
          this.SortingInfo.RangeEnd = this.SortingInfo.InitialPosition
          this.SortingInfo.Direction = -1
        }
      }
    },
    IsMoving (event :any) {
      if (this.Grapping !== null && this.TableSettings.Sorter) {
        var Table = document.getElementById(this.TableSettings.CookiesID)
        var HeeaderSize = Table?.getElementsByTagName('tr')[1].getBoundingClientRect().height
        var RowsSize = Table?.getElementsByTagName('tr')[2].getBoundingClientRect().height
        var TableTop = Table?.getBoundingClientRect().top
        if (TableTop && HeeaderSize) {
          TableTop = TableTop + HeeaderSize
        }
        var MouseTop = event.clientY
        var Top = MouseTop
        if (TableTop && RowsSize) {
          Top = Math.min(Math.max(0, MouseTop - TableTop), RowsSize * this.SearchedData.length)
        }
        var NewPlace = Top
        if (Top && RowsSize) {
          NewPlace = Math.min(Math.max(1, Math.ceil(Top / RowsSize)), this.SearchedData.length)
          this.Console(NewPlace)
        }
        // handle items
        this.SortingInfo.InitialPosition = this.DeepFetcher(this.GrappedItem, this.TableSettings.Sorter)
        this.SortingInfo.DestinationPostition = NewPlace
        this.SortingInfo.RangeStart = this.SortingInfo.InitialPosition
        this.SortingInfo.RangeEnd = this.SortingInfo.DestinationPostition
        this.SortingInfo.Direction = 1
        if (this.SortingInfo.InitialPosition > this.SortingInfo.DestinationPostition) {
          this.SortingInfo.RangeStart = this.SortingInfo.DestinationPostition
          this.SortingInfo.RangeEnd = this.SortingInfo.InitialPosition
          this.SortingInfo.Direction = -1
        }
      }
    },
    StopGrapping () {
      if (this.Grapping !== null) {
        this.Console('this.SortIndexEdited')
        this.Console(this.SortIndexEdited)
        var SortedSorter = this.SortIndexEdited.map((element :any) => {
          var Newlement = element
          if (this.TableSettings.Sorter) {
            this.DeepChanger(Newlement, this.TableSettings.Sorter, element.SorterIndex)
          }
          return Newlement
        })
        this.Console(SortedSorter)
        this.$emit('CustomSorting', { SortedData: SortedSorter, Sorter: this.TableSettings.Sorter })
        this.Grapping.style.opacity = 1
        this.Grapping = null
        this.GrappedItem = {}
      }
    },
    SelectAll (field :CookiesTableField) {
      var Select = true
      var CurrentSelection = this.MEMRead(this.TableSettings.CookiesID)
      if (CurrentSelection && CurrentSelection.length === this.SortedData.length) {
        console.log()
        Select = false
      }
      this.SortedData.forEach(element => {
        this.MEMWrite(this.TableSettings.CookiesID + '_selector_' + this.DeepFetcher(element, field.Res_ID ? field.Res_ID : ''), Select)
      })
    },
    RelativeClass (field :CookiesTableField, element :any) {
      if (!field.RelativeClass?.RelResID) return
      try {
        var RelValue = this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + field.RelativeClass?.RelName + '_' + this.DeepFetcher(element, field.RelativeClass?.RelResID))
        var Result = false
        switch (field.RelativeClass?.Operator) {
          case 'EQUAL LOWERCASE':
            if (RelValue === field.RelativeClass.FixedValue || RelValue.toLowerCase() === field.RelativeClass.FixedValue.toLowerCase()) Result = true
            break
          case 'NOT EQUAL LOWERCASE':
            if (RelValue !== field.RelativeClass.FixedValue && RelValue.toLowerCase() !== field.RelativeClass.FixedValue.toLowerCase()) Result = true
            break
          case 'EQUAL':
            if (RelValue === field.RelativeClass.FixedValue) Result = true
            break
          case 'NOT EQUAL':
            if (RelValue !== field.RelativeClass.FixedValue) Result = true
            break
          case 'LARGER':
            if (RelValue > field.RelativeClass.FixedValue) Result = true
            break
          case 'SMALLER':
            if (RelValue < field.RelativeClass.FixedValue) Result = true
            break
        }
        return Result ? field.RelativeClass?.ClassValue : ''
      } catch (error) {
      }
    },
    SenseElement (e :any, index:number) {
      if (this.Loading) {
        return
      }
      console.log(e)
      console.log(index)
      if ((index + 10) === this.LoadedElements && e.going === 'IN') {
        this.LoadedElements += 50
      }
    }
  },
  watch: {
    DefaultSort: function (newVal, oldVal) { // watch it
      this.ApplyTableSettings()
    },
    TableValue: function (newVal, oldVal) { // watch it
      this.MEMWrite(this.TableSettings.CookiesID, this.TableValue)
    },
    CookiesData: function (newVal, oldVal) { // watch it
      this.LoadedElements = 50
    }
  },
  unmounted () {
    if (!this.TableSettings.TableSelectMemory) {
      this.MEMClean(this.TableSettings.CookiesID)
    }
    const _this = this
    window.removeEventListener('mouseleave', function () {
      _this.StopGrapping()
    })
    window.removeEventListener('mouseup', function () {
      _this.StopGrapping()
    })
    window.removeEventListener('touchend', function () {
      _this.StopGrapping()
    })
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "4e7da09c": (_ctx.Theme.Light1),
  "af05b4ea": (_ctx.Theme.BG1),
  "548a834a": (_ctx.Theme.BGDimmed),
  "af05b4e8": (_ctx.Theme.BG2),
  "af05b4e0": (_ctx.Theme.BG6),
  "af05b4e4": (_ctx.Theme.BG4),
  "73e5bd0b": (_ctx.Theme.UserColors.main)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__