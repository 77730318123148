import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71245e35"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesDropDown = _resolveComponent("CookiesDropDown")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CookiesIcon = _resolveComponent("CookiesIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CookiesDropDown, {
      onSlection_changed: _ctx.SelectedThroughMenu,
      class: "OnlyMobile",
      Res: 'HeaderTabs_' + _ctx.Res,
      Options: _ctx.AccessableTabs,
      Selector: 'Name',
      Type: 'Object',
      Align: 'left',
      Radio: true,
      Width: '8em'
    }, null, 8, ["onSlection_changed", "Res", "Options"]),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.Class, "MobileHider TabContainer BGShade1 color2 D-Flex M-Auto"]),
      style: _normalizeStyle([{"background":"var(--shade2)"}, [`width: ${_ctx.TabsContainerWidth};`, _ctx.Style]])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.AccessableTabs, (tab) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: tab }, [
          (_ctx.Router)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "RepTabButton F-Bold",
                to: tab.Link ? tab.Link : ''
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(tab.Name), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass([_ctx.SelectedTab.Value === tab.Value ? 'SelectedTab': '', "RepTabButton D-Flex F-Bold Pointer"]),
                onClick: ($event: any) => (_ctx.Selected(tab))
              }, [
                _createTextVNode(_toDisplayString(tab.Name), 1),
                (tab.Icon !== undefined)
                  ? (_openBlock(), _createBlock(_component_CookiesIcon, {
                      key: 0,
                      class: "M-Auto",
                      style: {"width":"2em","height":"2em","display":"inline-block"},
                      ID: tab.Icon.ID
                    }, null, 8, ["ID"]))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_1))
        ], 64))
      }), 128))
    ], 6)
  ], 64))
}