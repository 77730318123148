<template>
  <div class="H-100 W-100 D-Flex" v-if="Loading">
    <div class="M-Auto">
      <CookiesLoading></CookiesLoading>
    </div>
  </div>
  <div class="H-100 W-100 D-Flex" v-if="Orders.length === 0">
    <div class="M-Auto">
      <span>{{Translate('No Data')}}</span>
    </div>
  </div>
  <apexchart v-else class="Color1" height="100%"
    type="donut"
    :options="chartOptions"
    :series="DriversArrays.Counts">
  </apexchart>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'

export default {
  created () {
    this.ReadOrders()
  },
  data () {
    return {
      Orders: [],
      Drivers: []
    }
  },
  props: {},
  methods: {
    ReadOrders () {
      this.Loading = true
      this.Get('DashBoard', '?Type=Export&' + this.DateAPIURL).then(response => {
        if (response !== null & response !== undefined) {
          this.Orders = response
        }
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Orders'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadDrivers () {
      this.Get('Employee/getdriver').then(response => {
        this.Drivers = response
      }, error => {
        error.CookiesError = 'Error in reading Drivers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  components: {
    apexchart: VueApexCharts
  },
  computed: {
    DriversArrays () {
      var Compouned = []
      this.Orders.forEach(element => {
        Compouned.push({
          ItemID: element.ItemID,
          Name: element.ItemCode ? element.ItemCode : '',
          Quantity: element.IsPack ? element.Quantity * element.PackSize : element.Quantity,
          Specefications: element.Specefications
        })
      })
      console.log(Compouned.length)
      var SortedCompuned = this.GeneralSort(Compouned, 'Quantity', -1)
      var ItemNames = SortedCompuned.map(item => {
        return item.Name
      }).slice(0, 50)
      var Counts = SortedCompuned.map(item => {
        return item.Quantity
      }).slice(0, 50)
      return {
        Items: ItemNames,
        Counts: Counts
      }
    },
    chartOptions () {
      return {
        chart: {
          foreColor: this.Theme.color3,
          id: 'vuechart-example'
        },
        colors: this.$store.state.CookiesChart.colors,
        labels: this.DriversArrays.Items,
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            donut: {
              size: '70%',
              labels: {
                show: true,
                value: {
                  formatter: function (y) {
                    return y
                  }
                }
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 1,
            opacityTo: 1,
            shade: 'dark',
            stops: [50, 80, 100]
          }
        },
        legend: {
          show: true,
          position: 'left',
          floating: false,
          useSeriesColors: true,
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: 'Roboto, Helvetica, Arial',
          formatter: function (seriesName, opts) {
            var ChartData = opts.w.globals.series
            var DataTotal = 0
            ChartData.forEach(element => {
              DataTotal = DataTotal + element
            })
            return (opts.w.globals.series[opts.seriesIndex] / DataTotal * 100).toFixed(1) + '%' + ' ' + seriesName
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadOrders()
      }
    },
    CurrentDate: function (newVal, oldVal) { // watch it
      this.ReadOrders()
    }
  }
}
</script>

  <style scoped>
  .CookiesCardFill{
    width: 100%;
    height: 100%;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    display: flex;
  }
  .CookiesChart{
    /* flex-direction: column;
    flex: 1;
    display: flex; */
    height: 100%;
    display: flex;
    flex: auto;
  }
  .vue-apexcharts{
    margin: auto;
  }
  </style>
