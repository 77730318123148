<template>
<div class="H-100 W-100 D-Flex" v-if="Loading">
  <div class="M-Auto">
    <CookiesLoading></CookiesLoading>
  </div>
</div>
<div class="CookiesCardFill" style="direction: ltr;">
  <div class="W-100" style="margin: 1em auto; ">
    <apexchart class="" height="100%"
      type="area"
      :options="chartOptions"
      :series="ValuesSeries">
    </apexchart>
  </div>
</div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'

export default {
  data () {
    return {
      Loading: true,
      CookiesDataRevenueJournalEntries: [],
      CookiesDataExpensesJournalEntries: [],
      CookiesDataArchivedOrders: [],
      Today: true,
      Decimals: 2,
      DateFrom: this.$store.state.CurrentDate.From,
      DateTo: this.$store.state.CurrentDate.To
    }
  },
  mounted () {
    this.ReadArchivedOrders()
  },
  components: {
    apexchart: VueApexCharts
  },
  computed: {
    TransactionsSeries () {
      // var NumberOfDays = Math.round((new Date(this.DateTo) - new Date(this.DateFrom)) / (1000 * 60 * 60 * 24)) + 1+
      var AllEntries = this.CookiesDataArchivedOrders
      var DatesTotals = {}
      // this.Console(new Date(this.DayNumber(this.DateTo) * 1000 * 60 * 60 * 24))
      AllEntries.forEach(element => {
        if (DatesTotals[this.JsonDateParser(element.SubmissionDate)] === undefined) {
          DatesTotals[this.JsonDateParser(element.SubmissionDate)] = {}
          DatesTotals[this.JsonDateParser(element.SubmissionDate)].Total = 1
          if (element.Status === 'Done') {
            DatesTotals[this.JsonDateParser(element.SubmissionDate)].Done = 1
          } else if (element.Status === 'Closed') {
            DatesTotals[this.JsonDateParser(element.SubmissionDate)].Closed = 1
          }
        } else {
          DatesTotals[this.JsonDateParser(element.SubmissionDate)].Total += 1
          if (element.Status === 'Done') {
            DatesTotals[this.JsonDateParser(element.SubmissionDate)].Done += 1
          } else if (element.Status === 'Closed') {
            DatesTotals[this.JsonDateParser(element.SubmissionDate)].Closed += 1
          }
        }
      })
      var DatesSeries = []
      var TotalSeries = []
      var DoneSeries = []
      var ClosedSeries = []
      var GeneralSeries = []
      Object.keys(DatesTotals).some(function (attr) {
        var element = {}
        element.Date = new Date(attr).toISOString()
        element.Total = DatesTotals[attr].Total
        element.Done = DatesTotals[attr].Done
        element.Closed = DatesTotals[attr].Closed
        GeneralSeries.push(element)
        // DatesSeries.push(new Date(attr).toISOString())
        // ValuesSeries.push(DatesTotals[attr])
      })
      var Sorted = GeneralSeries.sort(this.Sorting)
      DatesSeries = this.AttributeArray(Sorted, ['Date'])
      TotalSeries = this.AttributeArray(Sorted, ['Total'])
      DoneSeries = this.AttributeArray(Sorted, ['Done'])
      ClosedSeries = this.AttributeArray(Sorted, ['Closed'])
      // var AllEntries = StringArrayDebit.concat(StringArrayCredit)
      // this.Console(Math.round(this.CurrentDate.From - this.CurrentDate.To), 'color:purple;font-size:3em;')
      // var FinalArray = {AllEntries}
      return {
        DatesSeries: DatesSeries,
        TotalSeries: TotalSeries,
        DoneSeries: DoneSeries,
        ClosedSeries: ClosedSeries
      }
    },
    ValuesSeries () {
      if (this.TransactionsSeries.TotalSeries !== undefined) {
        return [{
          name: this.LanguageSelector('Total', 'العدد الكلي'),
          type: 'area',
          data: this.TransactionsSeries.TotalSeries,
          color: '#0091c4'
        },
        {
          name: this.LanguageSelector('Delivered', 'تم التوصيل'),
          type: 'area',
          data: this.TransactionsSeries.DoneSeries,
          color: '#67b45d'
        },
        {
          name: this.LanguageSelector('Returned', 'مرتجع'),
          type: 'area',
          data: this.TransactionsSeries.ClosedSeries,
          color: '#ff6b64'
        }
        ]
      } else {
        return []
      }
    },
    DatesSeries () {
      if (this.TransactionsSeries.DatesSeries !== undefined) {
        return this.TransactionsSeries.DatesSeries
      } else {
        return []
      }
    },
    chartOptions () {
      const _this = this
      return {
        theme: {
          palette: 'palette1',
          mode: this.$store.getters.CurrentTheme === 'light' ? 'light' : 'dark'
        },
        colors: ['#0091c4'],
        chart: {
          id: 'Transaction Chart',
          type: 'area',
          zoom: {
            enabled: false
          },
          // colors: ['#ff6b64', '#0091c4', '#67b45d', '#309587', '#ff9f64', '#cc6678', '#ffd238', '#ff9bf8', '#ffc064', '#50ceff'],
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: false,
              customIcons: []
            },
            export: {
              csv: {
                filename: 'CookiesBIS_Transactions',
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter (timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: 'CookiesBIS_Transactions'
              },
              png: {
                filename: 'CookiesBIS_Transactions'
              }
            }
          }
        },
        grid: {
          borderColor: _this.Theme.Shade0
        },
        stroke: {
          curve: 'smooth'
        },
        plotOptions: {
          line: {
            columnWidth: '100%',
            rangeBarOverlap: false
          }
        },
        dataLabels: {
          enabled: false
        },
        yaxis: {
          labels: {
            formatter: function (y) {
              return y.toFixed(0) + ' ' + _this.LanguageSelector('orders', 'طلب')
            }
          }
        },
        xaxis: {
          axisBorder: {
            color: _this.Theme.Shade1
          },
          type: 'datetime',
          categories: this.DatesSeries,
          labels: {
            rotate: -90,
            format: 'd MMM'
          }
        }
      }
    },
    CurrentDate () {
      var DateRange = {}
      DateRange.To = this.$store.getters.CurrentDate.To
      DateRange.From = this.$store.getters.CurrentDate.From
      return DateRange
    }
  },
  methods: {
    ReadArchivedOrders () {
      this.Loading = true
      this.Get('DoneOrders?' + this.DateAPIURL).then(response => {
        if (response !== null & response !== undefined) {
          this.CookiesDataArchivedOrders = response
        }
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Archived Orders'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    DecimalFixer (value) {
      return +parseFloat(value).toFixed(this.Decimals)
    },
    NewSale () {
      this.OpenDialog('Empty_Dialog', 'New Sell', '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ReadJournalEntries () {
      this.Loading = true
      this.Get('Financial/GetAccount?ID=' + this.AccountMapper.M_Revenue).then(response1 => {
        var RevenueLedgerID = this.LedgerFinder(this.DeepFetcher(response1[0], ['ledgers']), this.LedgersMapper.CollectedRevenue, 1).ID
        this.Get('Financial/JournalEntry?' + this.DateAPIURL + '&LedgerId=' + RevenueLedgerID).then(response2 => {
          this.Get('Financial/GetAccount?ID=' + this.AccountMapper.M_Expenses).then(response3 => {
            var ExpensesLedgerID = this.LedgerFinder(this.DeepFetcher(response3[0], ['ledgers']), this.LedgersMapper.Expenses, 1).ID
            this.Get('Financial/JournalEntry?' + this.DateAPIURL + '&LedgerId=' + ExpensesLedgerID).then(response4 => {
              this.CookiesDataRevenueJournalEntries = response2
              this.CookiesDataExpensesJournalEntries = response4
              this.Loading = false
            }, error => {
              this.Loading = false
              error.CookiesError = 'Error in reading Journal Entries'
              this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
            })
          }, error => {
            this.Loading = false
            error.CookiesError = 'Error in reading Revenue Account'
            this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
          })
        }, error => {
          this.Loading = false
          error.CookiesError = 'Error in reading Journal Entries'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Revenue Account'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    Sorting (a, b) {
      var SortElement = ['Date']
      var SortDirection = 1
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    DeepFetcher (object, path) {
      var value = object
      try {
        path.forEach(element => {
          value = value[element]
        })
        return value
      } catch (error) {
        return ''
      }
    },
    JsonDateParser (JsonDate) {
      var options = { dateStyle: 'medium' }
      var DateToParse = new Date(JsonDate)
      return DateToParse.toLocaleDateString('en-US', options)
    }
  },
  watch: {
    CurrentDate: function (newVal, oldVal) { // watch it
      this.ReadArchivedOrders()
    }
  }
}
</script>

<style scoped>
.CookiesCardFill{
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}
.CookiesCardCentered{
  width: 40%;
  padding: 0.3em;
  height: 100%;
  position: relative;
}
.NumbersBar{
  width: 90%;
  background: var(--shade1);
  margin: auto;
  height: 80%;
  margin-top: 7%;
  border-radius: var(--rad2);
  justify-content: center;
  overflow: hidden;
}
.CookieesNewLine{
  width: 100%;
}
.NumbersBarItem{
  width: 100%;
  margin: auto;
  height: 80%;
  vertical-align: middle;
  display: inline-flex;
  line-height: 1.4em;
  justify-content: center;
}
.NumberTitle{
  margin-top: 0.3em;
}

.LeftVSep{
  border-left: solid 1px var(--shade1);
  margin-left: -1px;
}
@media (min-width: 800px) {
  .NumberTitle{
    font-size: 0.85em !important;
  }
  .CookiesButton{
    font-size: 1em !important;
  }
}
.ToggleSelected{
  background: var(--white);
  opacity: 0.9;
}
.ToggleDeSelected{
  background: var(--wshade3);
  opacity: 0.4;
  color: var(--wshade3);
}
.NumberIcon{
  position: absolute;
  float: left;
  height:1.5em;
  z-index:-5;
}
.apexcharts-canvas{
  background: none !important;
}
</style>
