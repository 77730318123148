
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
import { spawnSync } from 'child_process'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      Acc: 0,
      OriginalData: {} as Section,
      Saving: false,
      SavingTable: [] as Array<'Saving'>,
      Loading: [] as Array<'Loading'>,
      Saved: undefined as boolean|undefined,
      CookiesData: {} as any,
      TransferItems: [] as any,
      AvailableItems: [] as any,
      Items: [] as any,
      Brands: [] as any,
      Categories: [] as any,
      CookiesSpecs: [] as any,
      TransferSources: [] as any,
      AddedItem: false,
      ErrorsVisibilty: false,
      CurrentImage: undefined as File | undefined,
      DialogMapper: [
        { Path: 'Items', Res: 'D_Transfer_Items', Default: [] },
        { Path: 'ExternalImportID', Value: this.DialogData.Data.ID },
        // { Path: 'CustomerName', Res: 'D_Transfer_Customer', Default: '' },
        // { Path: 'CustomerPhone', Res: 'D_Transfer_CustomerPhone', Default: '' },
        { Path: 'VehicleNumber', Res: 'D_Transfer_ImportVehicleNumber', Default: '' },
        { Path: 'Type', Value: this.DialogData.Type },
        { Path: 'Notes', Res: 'D_Transfer_Note', Default: '' }
        // { Path: 'SupplierID', Res: 'D_Transfers_ImportSource', ValuePath: 'ID', Default: null },
        // { Path: 'ContainerShip', Res: 'D_Transfer_ContainerShip', Default: '' },
        // { Path: 'SupplierName', Res: 'D_Transfers_SupplierName' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    ReadDialogData (InistialTime = false) {
      this.ReadItems(InistialTime)
      this.ReadTransferSources()
      this.ReadAvailableItems()
      this.ReadBrands()
      this.ReadCategories()
    },
    SaveLocal () {
      console.log('save')
    },
    printIT (id: string) {
      if (this.DialogData?.Data.Type === 'Export' && this.DialogData?.Data.Status === 'New') {
        this.Post('POST', 'Inventory_Transfers_UPDATE', {}, '?ID=' + this.DialogData?.Data.ID + '&Stat=Exporting').then(() => {
          this.$router.go(-1)
        }, error => {
          error.CookiesError = 'Error in updating Transfer'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
      var bodd = document.getElementById(id)
      if (!bodd) return
      if (this.$store.state.PrintingOptions && id === 'TableToPrint1') this.$store.state.PrintingOptions.FontSize = '0.95em'
      if (this.$store.state.PrintingOptions && id === 'TableToPrint2') this.$store.state.PrintingOptions.FontSize = '0.9em'
      if (this.$store.state.PrintingOptions && (this.DialogData?.Data.Type === 'Import' || this.DialogData?.Data.Type === 'ExternalImport')) this.$store.state.PrintingOptions.PageSize = 'A4 portrait'
      if (this.$store.state.PrintingOptions && (this.DialogData?.Data.Type === 'Import' || this.DialogData?.Data.Type === 'ExternalImport')) this.$store.state.PrintingOptions.Padding = '0em'
      if (this.$store.state.PrintingOptions && (this.DialogData?.Data.Type === 'Import' || this.DialogData?.Data.Type === 'ExternalImport')) this.$store.state.PrintingOptions.MaxWidth = '100%'
      this.$store.state.Printing = bodd.cloneNode(true) as HTMLElement
    },
    ShowLocations (Item :any) {
      console.log(Item)
      console.log(this.DecodedAval)
      var ItemAval = this.DecodedAval.filter((item :any) => {
        return Item.ID === item.ItemID
      })
      console.log(ItemAval)
      this.ViewLocDialog(ItemAval, Item.ID)
    },
    ViewLocDialog (ItemAval :any, ID: number) {
      this.OpenDialog('Inventory_AvailablItemLocations_Dialog', 'Locations', { Data: ItemAval, ItemID: ID }, { NoBackgroundClose: true, header: false })
    },
    RemoveItem (Item :any) {
      this.TransferItems.splice(Item.OriginalIndex, 1)
      this.SaveLocal()
      // if (!this.DialogData.Editing) {
      //   this.TransferItems.splice(Item.OriginalIndex, 1)
      // } else {
      //   this.Saving = true
      //   console.log(Item)
      //   this.Post('POST', 'Inventory_Transfers_UPDATE', Item, '?Stat=DeleteTransferItem').then(() => {
      //     this.Saving = false
      //     this.ReadTransferData()
      //   }, error => {
      //     this.Saving = false
      //     error.CookiesError = 'Error in Removing Item'
      //     this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      //   })
      // }
    },
    ReadItems (InistialTime = false) {
      this.Loading.push('Loading')
      this.Get('Inventory_Items').then(response => {
        this.Items = this.GeneralSort(response, 'Code', 1)
        // try {
        //   if (InistialTime && this.CookiesData && this.CookiesData.Items && this.CookiesData.Items.length > 0) {
        //     this.CookiesData.Items.forEach((transitem :any) => {
        //       var Found = this.Items.find((item:any) => {
        //         return item.ID === transitem.ItemID
        //       })
        //       if (Found) {
        //         Found.AvailableQuantity += transitem.Quantity
        //         this.TransferItems.push(this.DeepCloner({
        //           ...Found,
        //           ...transitem,
        //           ResID: transitem.ItemID + '_' + this.Acc
        //         }))
        //         this.Acc += 1
        //       }
        //     })
        //   }
        // } catch (error) {
        // }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadAvailableItems () {
      this.Loading.push('Loading')
      this.Get('Inventory_AvailableItems', '?TransferID=' + this.DialogData.Data.ID).then(response => {
        this.AvailableItems = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCategories () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=Items').then(response => {
        this.Categories = this.GeneralSort(response, 'Name', 1)
        if (this.DialogData.Data && this.DialogData.Data.CategoryID) {
          var SelectedCategory = this.Categories.find((cat :any) => {
            return cat.ID === this.DialogData.Data.CategoryID
          })
          if (SelectedCategory) {
            this.MEMWrite('D_Item_Category_Value', SelectedCategory)
            this.MEMWrite('D_Item_Category', SelectedCategory.Name)
          }
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadBrands () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=Brands').then(response => {
        this.Brands = this.GeneralSort(response, 'Name', 1)
        if (this.DialogData.Data && this.DialogData.Data.BrandID) {
          var SelectedBrand = this.Brands.find((brand :any) => {
            return brand.ID === this.DialogData.Data.BrandID
          })
          if (SelectedBrand) {
            this.MEMWrite('D_Item_Brand_Value', SelectedBrand)
            this.MEMWrite('D_Item_Brand', SelectedBrand.Name)
          }
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadSpecs () {
      if (!this.DialogData.Editing) return
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=ItemSpecs_' + this.DialogData.Data.ID).then(response => {
        this.CookiesSpecs = response.map((element :any) => {
          element.TempID = element.ID
          return element
        })
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveData () {
      if (this.DialogData.Editing) {
        this.Saving = true
        var EditedItems :Array<any> = []
        EditedItems = this.TransferItems.map((item :any) => {
          return {
            ...item,
            ItemID: item.ItemID,
            ItemName: item.Name,
            ItemCode: item.Code,
            IsUserSelect: item.IsUserSelect,
            AvalID: item.AvalID,
            IsPack: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID),
            PackSize: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID) ? this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack Size' + '_' + item.ResID) : 1,
            Quantity: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Quantity' + '_' + item.ResID),
            Details: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Details' + '_' + item.ResID)
          }
        })
        var EditedSupplier = this.MEMRead('D_Transfers_ImportSource_Value')
        if (EditedSupplier && EditedSupplier.Name) {
          this.MEMWrite('D_Transfers_EditedSupplierName', EditedSupplier.Name)
        }
        this.MEMWrite('D_Transfer_Items', EditedItems)
        this.Post('PATCH', 'Inventory_Transfers', this.DialogDataDecoder(this.DialogMapper, true), '?ID=' + this.DialogData.Data.ID + '&Stat=' + this.DialogData.Type + 'Edit').then(() => {
          this.Saving = false
          this.$router.go(-1)
        }, error => {
          this.Saving = false
          error.CookiesError = 'Error in Editing Item'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
        return
      }
      var SelectedItems :Array<any> = []
      var Supplier = this.MEMRead('D_Transfers_ImportSource_Value')
      if (Supplier && Supplier.Name) {
        this.MEMWrite('D_Transfers_SupplierName', Supplier.Name)
      }
      SelectedItems = this.TransferItems.map((item :any) => {
        return {
          AvalID: item.ID,
          ItemID: item.ItemID,
          ItemName: item.Item.Name,
          ItemCode: item.Item.Code,
          IsPack: item.IsPack,
          PackSize: item.PackSize,
          Quantity: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Quantity' + '_' + item.ResID),
          Details: item.Details,
          BranchID: item.BranchID,
          ItemLocationID: item.ItemLocationID
        }
      })
      this.MEMWrite('D_Transfer_Items', SelectedItems)
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        // PostAPI: 'Inventory_Transfers_' + this.DialogData.Type as 'Inventory_Transfers_Import' | 'Inventory_Transfers_Export',
        PostAPI: 'Inventory_Transfers_PartialImport',
        OriginalData: this.OriginalData,
        PatchAPI: 'Inventory_Transfers',
        Editing: this.DialogData.Editing,
        PatchQuery: this.DialogData.Editing ? '?ID=' + this.CookiesData.ID + '&Stat=ImportEdit' : ''
      })
    },
    InputChanged (Changed :any) {
      if (!this.DialogData.Editing) {
        return
      }
      var CurrentValue = this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + Changed.field.Name + '_' + Changed.element.ResID)
      if (this.CookiesData.Items && this.CookiesData.Items.length > 0) {
        var Found = this.CookiesData.Items.find((item :any) => {
          return item.ItemID === Changed.element.ItemID
        })
        var OriginalValue = Found[Changed.field.Value]
      }
    },
    CheckItem (Item: InventoryItem) {
      if (this.DialogData.Type === 'Export' && Item.AvailableQuantity === 0) {
        this.MEMClean('D_Transfer_Item', true)
        return
      }
      if (this.DialogData.Type === 'Export' && Item.IsUserSelect) {
        var Available = this.AvailableItems.filter((aval :any) => {
          return aval.ItemID === Item.ID
        })
        this.OpenDialog('Inventory_UserSelectItem_Dialog', this.Translate('Select Items'), { Data: { Item: Item, AvailableItems: Available, this: this } })
        return
      }
      this.AddItem(Item)
    },
    AddItem (Item: any) {
      // var found = this.TransferItems.find((element :any) => {
      //   return Item.IsUserSelect ? element.AvalID === Item.AvalID : Item.ID === element.ID
      // })
      // if (found) {
      //   this.MEMClean('D_Transfer_Item', true)
      //   return
      // }
      console.log(Item)
      this.AddedItem = false
      // var AvailableSpaces = this.DecodedAvailableItems.filter((element :any) => {
      //   return element.ItemID === Item.ID
      // })
      // AvailableSpaces.forEach((availitem :any) => {
      //   this.TransferItems.push({
      //     ResID: availitem.ID + '_' + this.Acc,
      //     ...this.DeepCloner(availitem)
      //   })
      //   this.Acc += 1
      // })
      this.TransferItems.push({
        ResID: Item.ID,
        ...this.DeepCloner(Item)
      })
      // this.TransferItems.push(Item)
      // setTimeout(() => {
      //   const scrollpoint = document.getElementById('scrollpoint')
      //   if (scrollpoint) {
      //     scrollpoint.scrollIntoView({ block: 'nearest', inline: 'nearest' })
      //   }
      // }, 100)
      setTimeout(() => {
        this.AddedItem = true
      }, 10)
      // setTimeout(() => {
      //   this.AddedItem = false
      // }, 2000)
      console.log(Item.Quantity)
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    },
    EditCategories (Type: string, Name: string) {
      this.OpenDialog('Main_Categories_Dialog', this.Translate('Edit ' + Name), { Data: {}, Type: Type })
    },
    ReadTransferSources () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=TransferSources').then(response => {
        this.TransferSources = response
        console.log(this.CookiesData)
        if (this.CookiesData && this.CookiesData.SupplierID) {
          var SelectedSource = this.TransferSources.find((Source :any) => {
            return Source.ID === this.CookiesData.SupplierID
          })
          if (SelectedSource) {
            this.MEMWrite('D_Transfers_ImportSource_Value', SelectedSource)
            this.MEMWrite('D_Transfers_ImportSource', SelectedSource.Name)
          }
        } else {
          this.MEMClean('D_Transfers_ImportSource')
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Sources'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ExportTableSettings (item :any) {
      var Table = {
        TableName: item.Name + `( ${item.Code} )`,
        CookiesID: 'D_ExportTransfer_Table_' + item.ID,
        ResID: 'ID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Pack Size',
            Type: 'normal',
            Value: 'PackSize',
            Res_ID: 'ResID'
          },
          {
            Name: 'Available',
            Type: 'normal',
            Value: 'Quantity',
            Bold: true,
            Visible: this.DialogData.Type === 'Export'
          },
          {
            Name: 'Quantity',
            Type: 'numberInput',
            numberInput_Default: 0,
            numberInput_Min: 0,
            Value: ['NOTHING'],
            Res_ID: 'ID',
            numberInput_Max: ['Quantity']
          },
          {
            Name: 'Total',
            Type: 'normal',
            Value: 'Total',
            Bold: true,
            Total: true
          }
        ],
        Footer: true,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'AddedDate'
        }
      } as CookiesTable
      return Table
    }
  },
  created () {
    this.CookiesData = this.DialogData.Data
  },
  mounted () {
    if (this.CookiesData) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.CookiesData) as Section
    }
    this.ReadDialogData(true)
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_Transfer')
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('TransferDIalogItems') + this.DialogData.Type ? ' ' + this.DialogData.Type : '',
        CookiesID: 'D_Transfer_Table',
        MultiLevel: true,
        ResID: 'ID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Item',
            Type: 'normal',
            Value: ['Item', 'Code'],
            Bold: true
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: ['Item', 'Name'],
            Bold: true
          },
          {
            Name: 'Category',
            Type: 'normal',
            Value: ['Item', 'CategoryName'],
            Bold: true
          },
          {
            Name: 'Specs',
            Type: 'array-normal',
            Value: ['Item', 'Specifications'],
            MultiValue: 'Description'
          },
          {
            Name: 'Max',
            Type: 'normal',
            Value: 'Quantity',
            Bold: true
          },
          {
            Name: 'Pack Size',
            Type: 'normal',
            Value: 'PackSize',
            Bold: true
          },
          {
            Name: 'Quantity',
            Type: 'numberInput',
            numberInput_Default: 1,
            numberInput_Min: 1,
            Value: 'NONE',
            Res_ID: 'ResID',
            numberInput_Max: 'Quantity',
            DynamicClass: {
              ClassList: this.DialogData.Type === 'Export' ? 'Disabled' : '',
              FixedValue: 1,
              Operator: 'EQUAL',
              ValuePath: 'IsUserSelect'
            }
          },
          {
            Name: 'Location',
            Type: 'normal',
            Value: 'DecodedLoc',
            Res_ID: 'ResID',
            Style: 'min-width: 10em;'
          },
          {
            Name: 'Delete',
            Value: '',
            Type: 'delete',
            NoHeader: true,
            NoPrint: true
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'SorterIndex'
        }
      } as CookiesTable
      return Table
    },
    ComputedSelectors () {
      if (this.DialogData.Type === 'Export') {
        return [['Code'], ['Name'], ['Specifications', 0, 'Description'], ['Specifications', 1, 'Description']]
      } else {
        return [['Code'], ['Name'], ['Specifications', 0, 'Description'], ['Specifications', 1, 'Description']]
      }
    },
    DecodedAvailableItems () {
      return this.DecodedAval.map((aval :any) => {
        var CurrentItem = this.Items.find((item :any) => {
          return item.ID === aval.ItemID
        })
        return {
          Item: CurrentItem,
          ...aval
        }
      })
    },
    ExportItems () : Array<any> {
      var MappedItems :Array<any> = this.DeepCloner(this.TransferItems)
      var NewItems = MappedItems.map((item:any) => {
        var ItemAvailableAll = this.AvailableItems.filter((availItem :any) => {
          return item.ID === availItem.ItemID && availItem.IsPack === this.MEMRead('D_Transfer_IsPack') && availItem.Type === 'Available'
        })
        var DecodedAvailable :Array<any> = []
        ItemAvailableAll.forEach((element :any) => {
          var Same = DecodedAvailable.find(Decoded => {
            return Decoded.IsPack === element.IsPack && Decoded.PackSize === element.PackSize
          })
          if (Same) {
            Same.Quantity += element.Quantity
          } else {
            DecodedAvailable.push({
              Total: element.PackSize * this.MEMRead('D_ExportTransfer_Table_' + element.ItemID + '_Edited_Quantity_' + element.ID),
              ...this.DeepCloner(element)
            })
          }
        })
        item.AvailableItems = DecodedAvailable
        return item
      })
      return NewItems
    },
    OrderItems () {
      return this.Items.filter((item :any) => {
        return this.DecodedAval.find((element :any) => {
          return element.ItemID === item.ID
        })
      })
    },
    FilteredItems () {
      var Filteredd :any = []
      var IsSearch = this.MEMRead('D_Transfer_Search') && this.MEMRead('D_Transfer_Search') !== ''
      var IsBrand = this.MEMRead('D_Transfer_Brand') && this.MEMRead('D_Transfer_Brand').ID !== undefined
      var IsCategory = this.MEMRead('D_Transfer_Brand') && this.MEMRead('D_Transfer_Category').ID !== undefined
      var Items = this.DecodedAvailableItems
      var TransferItems = this.TransferItems
      Filteredd = Items.filter((item :any) => {
        var ItemSelected = false
        ItemSelected = TransferItems.some((element :any) => {
          return item.ID === element.ID
        })
        var Search = this.MEMRead('D_Transfer_Search') === '' || this.CookiesSearcher(item, this.MEMRead('D_Transfer_Search'))
        var Brand = !this.MEMRead('D_Transfer_Brand').ID || (this.MEMRead('D_Transfer_Brand').ID === item.Item.BrandID)
        var Category = !this.MEMRead('D_Transfer_Category').ID || (this.MEMRead('D_Transfer_Category').ID === item.Item.CategoryID)
        var Filtered = false
        this.DecodedSpecs.forEach((spec :any) => {
          if (!this.MEMRead('D_Transfer_' + spec.Name)) return
          if (this.MEMRead('D_Transfer_' + spec.Name) === this.Translate('All')) return
          if (!item.Item.Specifications) return
          var SpecFound = item.Item.Specifications.find((itemspec :ItemSpec) => {
            return itemspec.Name === spec.Name
          })
          if (!SpecFound) {
            Filtered = true
            return
          }
          // console.log(Filtered)
          if (this.MEMRead('D_Transfer_' + spec.Name) !== SpecFound.Description) Filtered = true
          console.log(Filtered)
        })
        // console.log(Filtered)
        return !Filtered && !ItemSelected && (IsSearch ? Search : true) && (IsBrand ? Brand : true) && (IsCategory ? Category : true)
      })
      return Filteredd
    },
    CashedTranslations () {
      return {
        Name: this.Translate('Name'),
        Brand: this.Translate('Brand'),
        Category: this.Translate('Category'),
        Available: this.Translate('Available'),
        'Pack Size': this.Translate('Pack Size'),
        'Not Available': this.Translate('Not Available'),
        Specs: this.Translate('Specs')
      }
    },
    DecodedAval () {
      return this.AvailableItems.map((item :any) => {
        item.DecodedLoc = (item.WarehouseName && item.WarehouseName !== '' ? item.WarehouseName : '') + `(${item.WarehouseCode})` + (item.SectionLevel1ID ? (' > ' + (item.Section1Name && item.Section1Name !== '' ? item.Section1Name : '') + `(${item.Section1Code})`) : '') + (item.SectionLevel2ID ? (' > ' + (item.Section2Name && item.Section2Name !== '' ? item.Section2Name : '') + `(${item.Section2Code})`) : '') + ' > ' + (item.SectionName && item.SectionName !== '' ? item.SectionName : '') + `(${item.SectionCode})`
        return item
      })
    },
    FilteredCategories () {
      return this.Categories.filter((cat :any) => {
        return this.OrderItems.find((item :any) => {
          return item.CategoryID === cat.ID
        })
      })
    },
    FilteredBrands () {
      return this.Brands.filter((brand :any) => {
        return this.OrderItems.find((item :any) => {
          return item.BrandID === brand.ID
        })
      })
    },
    DecodedSpecs () {
      var SpecsMap :any = []
      this.OrderItems.forEach((item :InventoryItem) => {
        if (!item.Specifications) return
        item.Specifications.forEach((spec) => {
          var SpecFound = SpecsMap.find((MappedSpec :any) => {
            return MappedSpec.Name === spec.Name
          })
          if (!SpecFound) {
            SpecsMap[spec.Name] = []
            SpecsMap.push({
              Name: spec.Name,
              Description: [
                spec.Description
              ]
            })
          } else {
            var DesFound = SpecFound.Description.find((description :string) => {
              return description === spec.Description
            })
            if (!DesFound) {
              SpecFound.Description.push(spec.Description)
            }
          }
        })
      })
      return SpecsMap
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "8670cad6": (_ctx.Theme.Shade1),
  "9e32de68": (_ctx.Theme.Light1),
  "6b74b942": (_ctx.Theme.BG5)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__