
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as any,
      Saving: false,
      selectedFile: null,
      Loading: [] as Array<'Loading'>,
      Profiles: [],
      ErrorsVisibilty: false,
      Customers: [] as any[],
      Items: [] as any[],
      DialogMapper: [
        { Path: 'ItemName', Res: 'D_Sales_Order_ItemName', ValuePath: 'Name' },
        { Path: 'Price', Res: 'D_Sales_Order_Price' },
        { Path: 'Quantity', Res: 'D_Sales_Order_Quantity' },
        { Path: 'Description', Res: 'D_Sales_Order_Description' },
        { Path: 'CustomerID', Res: 'D_Sales_Order_Customer', ValuePath: 'ID', SavingPath: 'CustomerID' },
        { Path: 'Location', Res: 'D_Sales_Order_Location' },
        { Path: 'AddedCost', Res: 'D_Sales_Order_AddedCost' },
        { Path: 'CustomDate', Res: 'D_Sales_Order_CustomDate' },
        { Path: 'Discount', Res: 'D_Sales_Order_Discount' },
        { Path: 'SerialNO', Value: 's' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    ReadDialogData () {
      this.ReadCustomers()
      this.ReadItems()
    },
    SaveData () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'Sales_Orders',
        OriginalData: this.OriginalData,
        PatchAPI: 'Sales_Orders',
        Editing: this.DialogData.Editing,
        PatchQuery: this.DialogData.Editing ? '?ID=' + this.DialogData.Data.ID : ''
      })
    },
    ReadCustomers () {
      this.Loading.push('Loading')
      this.Get('CRM_Customers', '?Type=c').then(response => {
        this.Customers = this.GeneralSort(response.map((value: any) => { return { ...value, ID: value.ID.toString(), Serial: '#' + value.ID } }), 'ID', -1)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadItems () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=SalesItems').then(response => {
        this.Items = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    },
    NewCustomer () {
      this.OpenDialog('CRM_Customer_Dialog', this.Translate('New Customer'), {})
    },
    EditCategories (Type: string, Name: string) {
      this.OpenDialog('Main_Categories_Dialog', this.Translate('Edit ' + Name), { Data: {}, Type: Type })
    }
  },
  created () {
    if (this.DialogData.Editing) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData.Data)
    }
    this.ReadDialogData()
  },
  mounted () {
    this.MEMWrite('D_Sales_Order_CustomDate', new Date().toISOString().slice(0, 10))
    if (this.DialogData.Editing) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData.Data)
      if (this.DialogData.Editing && this.DialogData.Data && this.DialogData.Data.CustomDate) {
        this.MEMWrite('D_Sales_Order_CustomDate', new Date(this.DialogData.Data.CustomDate).toISOString().slice(0, 10))
      }
    }
    // this.MEMWrite('D_Sales_Order_CustomDate', new Date())
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadDialogData()
      }
    }
  }
})
