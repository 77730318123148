import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bea5e58"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesIcon = _resolveComponent("CookiesIcon")!

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass([ [_ctx.ErrorData && ' ErrorBorder ', (_ctx.Disabled || _ctx.Selecting) && ' Disabled '], "UserImage M-Auto P-Rel"])
  }, [
    _createElementVNode("input", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Clicked())),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectionDone())),
      onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.UserImageChanged())),
      name: _ctx.Res,
      type: "file"
    }, null, 40, _hoisted_1),
    _createVNode(_component_CookiesIcon, {
      class: "",
      style: {"position":"absolute","inset":"0.3em"},
      ID: 'Clone'
    }),
    (_ctx.SelectedImgUrl !== '')
      ? (_openBlock(), _createBlock(_component_CookiesIcon, {
          key: 0,
          class: "",
          style: {"position":"absolute","inset":"0.8em","bottom":"1.2em","top":"-0.4em","left":"-1.2em"},
          ID: 'Done'
        }))
      : _createCommentVNode("", true)
  ], 2))
}