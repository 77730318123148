import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "H-100 W-100 D-Flex" }
const _hoisted_2 = {
  key: 0,
  class: "M-Auto"
}
const _hoisted_3 = {
  key: 1,
  class: "M-Auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesLoading = _resolveComponent("CookiesLoading")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.Loading || _ctx.LocalLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_CookiesLoading)
        ]))
      : (!_ctx.CookiesData || !_ctx.CookiesData.Data || _ctx.CookiesData.Data.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.Translate('No Data')), 1)
          ]))
        : (_openBlock(), _createBlock(_component_apexchart, {
            key: 2,
            class: "Color1 W-100 H-100",
            width: "100%",
            height: "100%",
            options: _ctx.chartOptions,
            series: _ctx.Series,
            onDataPointSelection: _ctx.clickHandler
          }, null, 8, ["options", "series", "onDataPointSelection"]))
  ]))
}