
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    ItemsLocations: { Default: () => { return [] }, type: Array as PropType<Array<any>> },
    SectionID: { type: Number as PropType<number> },
    SectionVolume: { type: Number as PropType<number> },
    Res: { type: String as PropType<string> }
  },
  data () {
    return {}
  },
  computed: {
    TotalVolume () {
      var TotalVolume = 0
      this.SectionItems().forEach(item => {
        TotalVolume += item.TotalVolume
      })
      return TotalVolume
    }
  },
  // ---------------------------------------------------
  methods: {
    SectionItems () {
      // return this.ItemsLocations
      if (!this.ItemsLocations) return []
      var SectionLocations = this.ItemsLocations.filter((itemlocation :any) => {
        return itemlocation.SectionID === this.SectionID
      })
      var mappedItems = SectionLocations.map((item :any) => {
        return {
          ...item,
          TotalVolume: (item.TotalOrderedQuantity + item.TotalAvailableQuantity + item.TotalReservedQuantity) * item.ItemVolume
        }
      })
      var Sorted = this.GeneralSort(mappedItems, 'TotalVolume', -1)
      this.MEMWrite(this.Res, Sorted)
      return Sorted
    },
    EditItem (item :any) {
      this.OpenDialog('Inventory_ItemLocation_Dialog', this.Translate('Edit Item Location'), {
        Data: item,
        Editing: true,
        SectionID: this.SectionID
      })
    },
    Tooltip (item : any) {
      return this.Translate('Available') + ':' + item.AvailableQuantity + ' -- ' + this.Translate('Reserved') + ':' + item.ReservedQuantity + ' -- ' + this.Translate('Importing') + ':' + item.OrderedQuantity + ' -- ' + this.Translate('Empty') + ':' + (item.QuantityMax - (item.OrderedQuantity + item.AvailableQuantity + item.ReservedQuantity))
    },
    RemoveItemLocation (e :Event, item : any) {
      e.preventDefault()
      var eve :any = event
      if (eve && eve.preventDefault) eve.preventDefault()
      if (!this.Access('Inventory_Sections_Edit')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Inventory_ItemsLocation', AddQuery: '?ID=' + item.ID, Text: this.Translate('Are you sure you want to delete this location for') + ' <strong>' + item.ItemName + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    LevelLocationsValue (e :Event, item : any) {
      e.preventDefault()
      var eve :any = event
      if (eve && eve.preventDefault) eve.preventDefault()
      if (!this.Access('Inventory_AvailableItems_Override')) {
        return
      }
      this.OpenDialog('Inventory_AvailableItemsOverride_Dialog', this.Translate('Override Quantity'), item, { NoBackgroundClose: true })
    },
    SectionPercentage (item :any) {
      if (!this.SectionVolume) return 0
      return ((((item.TotalVolume)) / (this.SectionVolume)) * 100)
    },
    OrderedVolume (item :any) {
      if (!this.SectionVolume) return 0
      return ((1 - ((item.TotalOrderedQuantity + item.TotalAvailableQuantity) * item.ItemVolume / (item.TotalVolume))) * 100)
    },
    AvailableVolume (item :any) {
      if (!this.SectionVolume) return 0
      return ((1 - (item.TotalAvailableQuantity * item.ItemVolume / (item.TotalVolume))) * 100)
    }
  },
  // ---------------------------------------------------
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "4aea0045": (_ctx.Theme.Shade3),
  "5a69582c": (_ctx.Theme.UserColors.fourth_50),
  "4e717e98": (_ctx.Theme.UserColors.Dimmed),
  "29fa20ed": (_ctx.Theme.UserColors.main),
  "15722792": (_ctx.Theme.UserColors.second_50),
  "1572273a": (_ctx.Theme.UserColors.second_25)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__