const routes :Array<CookiesRouter> = [
  {
    path: '/Inventory',
    name: 'Inventory',
    meta: {
      Access: 'Inventory_View' as AccessFeature
    },
    component: () => import('@/Apps/Inventory/views/Inventory.vue'),
    children: [
      {
        path: '/Inventory/Catalogs',
        name: 'EditCatalogs',
        component: () => import('@/Apps/Inventory/views/Catalogs.vue'),
        meta: {
          Access: 'Catalogue_Read' as AccessFeature
        }
      },
      {
        path: '/Inventory/Warehouses',
        name: 'Warehouses',
        component: () => import('@/Apps/Inventory/views/Warehouses.vue'),
        meta: {
          Access: 'Inventory_Warehouses_Read' as AccessFeature
        }
      },
      {
        path: '/Inventory/Items',
        name: 'Items',
        component: () => import('@/Apps/Inventory/views/Items.vue'),
        meta: {
          Access: 'Inventory_Items_Read' as AccessFeature
        }
      },
      {
        path: '/Inventory/Exports',
        name: 'Exports',
        component: () => import('@/Apps/Inventory/views/Exports.vue'),
        meta: {
          Access: 'Inventory_Transfers_Read' as AccessFeature
        }
      },
      {
        path: '/Inventory/Imports',
        name: 'Imports',
        component: () => import('@/Apps/Inventory/views/Imports.vue'),
        meta: {
          Access: 'Inventory_Transfers_Read' as AccessFeature
        }
      },
      {
        path: '/Inventory/InternalTransfers',
        name: 'InternalTransfers',
        component: () => import('@/Apps/Inventory/views/InternalTransfers.vue'),
        meta: {
          Access: 'Inventory_Transfers_Read' as AccessFeature
        }
      }
    ]
  },
  // ====================
  // ============================================================================== Catolgs
  {
    path: '/Inventory/ViewCatalog/:CatalogID/:ItemID?',
    name: 'ViewCatalog',
    component: () => import('@/Apps/Inventory/views/Catalogs/ViewCatalog.vue'),
    meta: {
      Access: 'Main_Public' as AccessFeature
    }
  },
  {
    path: '/Inventory/ViewAllCatalogs',
    name: 'ViewAllCatalogs',
    component: () => import('@/Apps/Inventory/views/Catalogs/ViewAllCatalogs.vue'),
    meta: {
      Access: 'Inventory_Public' as AccessFeature,
      NoHeader: true
    }
  },
  {
    path: '/all',
    redirect: '/Inventory/ViewAllCatalogs',
    meta: {
      Access: 'Main_Public' as AccessFeature,
      NoHeader: true
    }
  },
  {
    path: '/I/Q/:CatalogID/:ItemID',
    name: 'ViewItem',
    component: () => import('@/Apps/Inventory/views/Catalogs/QRCatalogItemHandler.vue'),
    meta: {
      Access: 'Main_Public' as AccessFeature
    }
  }
  // ==============================================================================
  // ====================
]

export const InventoryRoutes = routes
