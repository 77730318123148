
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadOrders()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, this.Filter)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<any>,
      MappedCData: {} as any,
      Profiles: [] as Array<Profile>
    }
  },
  computed: {
    Filter () :FilterObject {
      return {
        Values: [
          {
            Name: 'Filter By Total',
            DataPath: 'Total',
            Res: 'CustomerTotalValueFilter'
          }
        ]
      }
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Customer',
          this: this,
          Function: 'NewCustomer',
          Icon: 'Add',
          Visible: this.Access('CRM_Customers_Create')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        DefaultSort: { Direction: -1, Path: 'ID' },
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Serial',
            Bold: true,
            Path: ['ID'],
            Sort: ['ID'],
            Hash: '#',
            IsSearchable: true
          },
          {
            Type: 'DateTime',
            Name: 'Date',
            Path: 'AddedDate',
            Sort: 'AddedDate'
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name'],
            Bold: true,
            Class: 'F1_25em Pointer',
            IsSearchable: true,
            Emits: 'OpenVendor'
          },
          {
            Type: 'Normal',
            Name: 'Notes',
            Path: ['Description'],
            Sort: ['Description'],
            MultiLine: true,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Phone Number',
            Path: ['PhoneNumber'],
            Sort: ['PhoneNumber'],
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Remaining',
            Path: 'Total',
            Sort: 'Total',
            Bold: true,
            Class: 'F1_25em',
            Unit: this.PriceUnit,
            IsSearchable: true
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Expand',
                Visible: this.Access('Purchase_Vendors_Read'),
                TooltTip: this.Translate('Details'),
                Emits: 'OpenVendor'
              },
              {
                ID: 'Phone',
                Visible: this.Access('CRM_Customers_Read'),
                TooltTip: this.Translate('Call'),
                Link: 'PhoneCall'
              },
              {
                ID: 'Whatsapp',
                Visible: this.Access('CRM_Customers_Read'),
                TooltTip: this.Translate('Open Whatsapp'),
                Emits: 'Whatsapp'
              },
              {
                ID: 'Edit',
                Visible: this.Access('CRM_Customers_Edit'),
                TooltTip: this.Translate('Edit'),
                Emits: 'EditBranch',
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: false,
                  ValuePath: 'IsNew'
                }
              },
              {
                ID: 'Delete',
                Emits: 'DeleteBranch',
                Visible: this.Access('CRM_Customers_Remove'),
                TooltTip: this.Translate('Remove'),
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: false,
                  ValuePath: 'IsNew'
                }
              },
              {
                ID: 'Edit',
                Visible: this.Access('CRM_Customers_Create'),
                TooltTip: this.Translate('Edit'),
                Emits: 'EditBranch',
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: true,
                  ValuePath: 'IsNew'
                }
              },
              {
                ID: 'Delete',
                Visible: this.Access('CRM_Customers_Create'),
                Emits: 'DeleteBranch',
                TooltTip: this.Translate('Remove'),
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: true,
                  ValuePath: 'IsNew'
                }
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.ModifiedData, this.Filter, this.Search)
    },
    ModifiedData () {
      var DateSpan = new Date(new Date(this.MEMRead('CurrentNetTime')).getTime() - (60 * 60 * 24 * 1000))
      return this.CookiesData.map((value) => {
        return {
          ...value,
          // Total: Number(this.DecimalFixer(this.MappedCData['c' + value.ID])),
          IsNew: new Date(value.AddedDate) > DateSpan
        }
      })
    }
  },
  methods: {
    ReadCookiesData () {
      fetch('https://timeapi.io/api/Time/current/zone?timeZone=Europe/Amsterdam').then(response => response.json())
        .then(data => {
          // Handle the data received from the API
          this.MEMWrite('CurrentNetTime', data?.dateTime)
        })
        .catch(error => {
          // Handle any errors that occurred during the fetch
          console.error(error)
        })
      this.Loading.push('Loading') // Add Loading flag
      this.Get('CRM_Customers', '?Type=c').then(response => {
        this.CookiesData = response.map((value: any) => {
          // var Total = 0
          // this.forEach((value) => {
          //   value.addedUted
          // })
          return { ...value, PhoneCall: 'tel:' + value.PhoneNumber, Total: this.DeepCloner(this.MappedCData['c' + value.ID] !== undefined ? this.MappedCData['c' + value.ID] : 0) }
        })
        console.log(this.CookiesData)
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadOrders () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Purchase_Orders').then(response => {
        this.MappedCData = {}
        response.forEach((value: any) => {
          if (!this.MappedCData['c' + value.CustomerID]) this.MappedCData['c' + value.CustomerID] = 0
          this.MappedCData['c' + value.CustomerID] += (value.Price * value.Quantity + value.AddedCost + value.Discount)
        })
        this.ReadTransactions()
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadTransactions () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Finance_Transactions').then(response => {
        response.forEach((value: any) => {
          if (!this.MappedCData['c' + value.CustomerID]) this.MappedCData['c' + value.CustomerID] = 0
          this.MappedCData['c' + value.CustomerID] -= (typeof value.Value === 'string' ? parseFloat(value.Value) : value.Value - value.paymentDiscountValue)
        })
        this.ReadCookiesData()
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditBranch (Customer: any) {
      this.OpenDialog('CRM_Customer_Dialog', this.Translate('Edit Customer') + ' (' + Customer.Name + ')', { Data: Customer, Editing: true }, {})
    },
    DeleteBranch (Branch: any) {
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'CRM_Customers', AddQuery: '?ID=' + Branch.ID, Text: 'Are you sure you want to delete <strong>' + Branch.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.BranchDeleted, AfterDialogFunctionValue: Branch.ImgUrl } as GeneralAcceptDialogData, {})
    },
    BranchDeleted (ImgUrl :string) {
      if (ImgUrl) this.DeleteImage(ImgUrl)
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewCustomer':
          this.OpenDialog('CRM_Customer_Dialog', this.Translate('New Customer'), {})
          break
      }
    },
    ViewLocation (Branch :Branch) {
      window.open('https://www.google.com/maps/@' + Branch.GPSLongitude + ',' + Branch.GPSLatitude)
    },
    Whatsapp (customer: any) {
      this.OpenWhatsapp(customer.PhoneNumber)
    },
    OpenVendor (Order :any) {
      this.OpenDialog('Purchase_VendorView_Dialog', this.Translate('View Customer'), { Data: { VendorID: Order.ID } })
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadOrders()
      }
    },
    Filter: function (newVal, oldVal) { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
