const routes :Array<CookiesRouter> = [
  {
    path: '/Catalogs',
    name: 'Catalogs',
    meta: {
      Access: 'Catalogue_View' as AccessFeature
    },
    component: () => import('@/Apps/Catalogs/views/Catalogs.vue'),
    children: [
      {
        path: '/Catalogs/Edit',
        name: 'EditCatalogs',
        component: () => import('@/Apps/Catalogs/views/EditCatalogs.vue'),
        meta: {
          Access: 'Catalogue_Read' as AccessFeature
        }
      }
    ]
  },
  // ====================
  // ============================================================================== Catolgs
  {
    path: '/Catalogs/View/:CatalogID/:ItemID?',
    name: 'ViewCatalog',
    component: () => import('@/Apps/Catalogs/views/ViewCatalog.vue'),
    meta: {
      Access: 'Main_Public' as AccessFeature
    }
  },
  {
    path: '/Catalogs/ViewAllCatalogs',
    name: 'ViewAllCatalogs',
    component: () => import('@/Apps/Catalogs/views/ViewAllCatalogs.vue'),
    meta: {
      Access: 'Main_Public' as AccessFeature,
      NoHeader: true
    }
  },
  {
    path: '/Catalogs/ViewCatalogs',
    name: 'ViewCatalogs',
    component: () => import('@/Apps/Catalogs/views/ViewCatalogs.vue'),
    meta: {
      Access: 'Main_Public' as AccessFeature,
      NoHeader: true
    }
  },
  {
    path: '/catalog',
    redirect: '/Catalogs/ViewCatalogs',
    meta: {
      Access: 'Main_Public' as AccessFeature,
      NoHeader: true
    }
  },
  {
    path: '/catalog/all',
    redirect: '/Catalogs/views/ViewAllCatalogs',
    meta: {
      Access: 'Main_Public' as AccessFeature,
      NoHeader: true
    }
  },
  {
    path: '/C/Q/:CatalogID/:ItemID',
    name: 'ViewItem',
    component: () => import('@/Apps/Catalogs/views/QRCatalogItemHandler.vue'),
    meta: {
      Access: 'Main_Public' as AccessFeature
    }
  }
  // ==============================================================================
  // ====================
]

export const CatalogsRoutes = routes
