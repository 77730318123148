
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Width: { default: '', type: String },
    Height: { default: '', type: String },
    RowHeight: { default: '2em', type: String },
    Default: { default: '' },
    Background: { default: '', type: String },
    Options: { default: () => { return [] }, type: Array as PropType<Array<any>> },
    Selectors: { default: () => { return ['Name'] }, type: Array as PropType<Array<ObjectPath>> },
    Icon: { default: '', type: String },
    Res: { type: String },
    Disabled: { default: false, type: Boolean },
    Radio: { default: false, type: Boolean },
    Name: { default: '', type: String },
    ErrorData: { default: false },
    PreserveMemory: { default: false },
    CreateNew: { default: false, type: Boolean },
    ResID: { default: () => { return undefined }, type: Object as PropType<ObjectPath> }
  },
  emits: {
    selected: null,
    changed: null,
    removed: null
  },
  data () {
    return {
      NameOnFocus: false,
      NewValue: false,
      Selected: '',
      SelectedObj: {},
      DefautlImage: require('@/assets/defaults/DefaultImage.svg')
    }
  },
  created () {
    if (!this.MEMRead(this.Res)) {
      this.MEMWrite(this.Res, this.Default)
    }
  },
  mounted () {
    if (this.MEMObjValue !== this.SelectedObj) {
      this.SelectedObj = this.MEMObjValue
      this.Selected = this.DeepFetcher(this.MEMObjValue, this.SelectorsFixed[0])
      // this.$emit('selected', this.SelectedObj)
    }
  },
  methods: {
    DelayDefocus () {
      if (this.Res && this.$refs[this.Res] && (this.$refs[this.Res]).matches(':focus-within:not(:focus)')) {
        return
      }
      setTimeout(() => {
        if (this.MEMRead(this.Res) === '') {
          this.Selected = ''
          this.SelectedObj = {}
          this.$emit('removed', '')
          this.MEMWrite(this.Res + '_Value', undefined)
        } else {
          var Matches = this.Options.filter(element => {
            return this.DeepFetcher(element, this.Selectors[0]) === this.MEMRead(this.Res)
          })
          var TextSelect = this.ObjectFinder(this.Options, this.Selectors[0], this.MEMRead(this.Res))
          if (Matches.length === 1 && TextSelect) {
            this.MEMWrite(this.Res, this.DeepFetcher(TextSelect, this.SelectorsFixed[0]) ? this.DeepFetcher(TextSelect, this.SelectorsFixed[0]) : '')
            this.MEMWrite(this.Res + '_Value', TextSelect)
          } else if (this.CreateNew) {
            // this.MEMWrite(this.Res, this.Selected ? this.Selected : '')
            // this.MEMWrite(this.Res + '_Value', this.SelectedObj)
          } else {
            this.MEMWrite(this.Res, this.Selected ? this.Selected : '')
            this.MEMWrite(this.Res + '_Value', this.SelectedObj)
          }
        }
        this.NameOnFocus = false
      }, 20)
    },
    SelectElement (element :any) {
      this.MEMWrite(this.Res, this.DeepFetcher(element, this.SelectorsFixed[0]) ? this.DeepFetcher(element, this.SelectorsFixed[0]) : '')
      this.Selected = this.MEMRead(this.Res)
      this.SelectedObj = element
      this.NameOnFocus = false
      this.$emit('selected', element)
      this.MEMWrite(this.Res + '_Value', element !== undefined ? element : '')
    },
    SearchInput (item :any) {
      var SearchLow = this.MEMRead(this.Res).toString().toLowerCase()
      // var itemLow = item.toString().toLowerCase()
      // if (itemLow.search(this.$store.state.CookiesMemory[this.Res].toString().toLowerCase()) === -1) {
      //   return { display: 'none' }
      // }
      // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
      if (SearchLow && SearchLow !== '') {
        var SearchIt = this.CookiesSearcher(item, SearchLow)
      } else {
        SearchIt = true
      }
      // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      if (SearchIt) {
        return { display: '' }
      } else {
        return { display: 'none' }
      }
    },
    InputChanged () {
      this.$emit('changed')
    },
    focused () {
      this.NameOnFocus = true
      this.Console('NameOnFocus')
      this.Console(this.MEMRead(this.Res))
      this.Selected = this.MEMRead(this.Res)
    },
    KeyPressed (element :any, i :number, event: KeyboardEvent) {
      this.Console(event.key)
      if ((event.key) === 'Escape') { // Escape
        this.$refs[this.Res + '_' + i][0].blur()
      } else if ((event.key) === 'Enter' || (event.key) === ' ') { // space + enter
        this.SelectElement(element)
      } else if ((event.key) === 'ArrowDown') { // down
        try {
          this.$refs[this.Res + '_' + (i + 1)][0].focus()
        } catch (error) {
        }
      } else if ((event.key) === 'ArrowUp') { // up
        try {
          this.$refs[this.Res + '_' + (i - 1)][0].focus()
        } catch (error) {
        }
      }
    },
    RemoveSelection () {
      this.MEMWrite(this.Res, '')
      this.DelayDefocus()
    },
    NameHandler (value :ObjectPath) {
      if (typeof value === 'string') {
        return this.Translate(value)
      } else if (Array.isArray(value)) {
        var LastInPath = value[value.length - 1]
        return this.Translate(typeof (LastInPath) === 'string' ? LastInPath : '')
      }
    }
  },
  computed: {
    ALLOptions () {
      if (this.Radio) {
        return this.Options
      }
      return [{ [this.Selectors[0] as string]: 'All' }].concat(this.Options)
    },
    FilteredOptions () {
      var SearchLow = this.MEMRead(this.Res).toString().toLowerCase()
      if (SearchLow && SearchLow !== '') {
        return this.Options.filter(element => {
          this.CookiesSearcher(element, SearchLow)
        })
      } else {
        return this.Options
      }
    },
    StyledOut () {
      return {
        width: this.Width,
        height: this.Height,
        background: this.Background
      }
    },
    SelectorsFixed () {
      var StringFixer = this.PathFixer(this.Selectors as ObjectPath) // handle single string prop into single array |||| 'prop' => ['prop']
      if ((typeof StringFixer[0]) === 'string') {
        return [StringFixer]
      } else {
        var Fixed = StringFixer.map(function (item) { // handle multiple prop for multi selector strings into arrays arrays |||| [['selector1'], ['selector2a', 'selector2b']] => [['selector1'], ['selector2a', 'selector2b']]
          if (typeof (item) === 'string') {
            return [item]
          }
          return item
        })
        return Fixed
      }
    },
    Resource () {
      return this.MEMRead(this.Res)
    },
    MEMValue () {
      return this.MEMRead(this.Res)
    },
    MEMObjValue () {
      return this.MEMRead(this.Res + '_Value')
    },
    StyleDirection () {
      var WindowH = window.innerHeight
      var ElementY = this.Res && this.$refs[this.Res].getBoundingClientRect().bottom
      if ((ElementY < (WindowH / 2)) || (WindowH - ElementY > 400)) {
        return 'top: 3.3em;'
      } else {
        return 'bottom: 3.3em;'
      }
    }
  },
  watch: {
    MEMValue: function (newVal, oldVal) { // watch it
      if (this.MEMValue === '') {
        this.Selected = ''
        this.SelectedObj = {}
      }
      if (this.MEMValue === undefined) {
        this.MEMWrite(this.Res, '')
      }
    },
    MEMObjValue: function (newVal, oldVal) { // watch it
      if (this.MEMObjValue !== this.SelectedObj && this.MEMObjValue !== undefined) {
        this.SelectedObj = this.MEMObjValue
        this.Selected = this.DeepFetcher(this.MEMObjValue, this.SelectorsFixed[0])
        this.MEMWrite(this.Res, this.Selected ? this.Selected : '')
        this.$emit('selected', this.SelectedObj)
      }
    }
  },
  unmounted () {
    if (!this.PreserveMemory && this.Res) {
      this.MEMClean(this.Res)
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "2ea3a02e": (_ctx.Theme.InputColor),
  "62d7c084": (_ctx.$store.getters.DefaultTextAlign),
  "4e97d111": (_ctx.Theme.UserColors.third_50),
  "393cbe07": (_ctx.Theme.color5),
  "5d0781ca": (_ctx.Theme.BG3Fixed),
  "28d5b7d9": (_ctx.Theme.BG3),
  "1d876499": (_ctx.Theme.Shade1),
  "393cbe05": (_ctx.Theme.color3)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__