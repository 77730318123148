
import { defineComponent } from 'vue'
import CookiesCoreMixin from '@/modules/CookiesCoreMixin.vue'
const __default__ = defineComponent({
  mixins: [CookiesCoreMixin],
  data () {
    return {
      Email: '',
      Password: '',
      ErrorsVisibilty: false,
      UserNameServerErrors: '',
      PassServerErrors: '',
      ServerError: '',
      ShowPass: false
    }
  },
  mounted () {
    var savedEmail = this.LocalRead('CookiesUserName')
    this.Console('savedEmail')
    this.Console(savedEmail)
    if (savedEmail !== null) {
      this.Email = savedEmail
      this.MEMWrite('SignInRememberMe', true)
    }
    try {
      const SigninDialog = document.getElementById('CookiesSignDialog')
      const DialogPos = SigninDialog ? SigninDialog.offsetTop - 60 : 0
      window.scrollTo(0, DialogPos)
    } catch (error) {
    }
  },
  computed: {
    DialogErrors () {
      var ERR = []
      if (!this.Email || this.Email === '') {
        ERR.push('Enter Email')
      }
      if (!this.Password || this.Password === '') {
        ERR.push('Enter Password')
      }
      return ERR
    }
  },
  methods: {
    SignIN () {
      const _this = this
      if (this.DialogErrors.length > 0) {
        this.ErrorsVisibilty = true
        return
      }
      this.UserNameServerErrors = ''
      this.PassServerErrors = ''
      this.ServerError = ''
      this.ErrorsVisibilty = false
      // ///testing only///////////////////
      // this.$store.state.UserManagment.CurrentUser.User_Type = 'ADMIN'
      // this.$store.state.UserManagment.CurrentUser.User_Name = 'Khaled'
      // this.$store.state.UserManagment.CurrentUser.User_Email = 'Mail@Mail.com'
      // this.$store.state.UserManagment.Status = true
      // if (this.$store.state.UserManagment.CurrentUser.User_Type === 'ADMIN') {
      //   this.$router.replace('/')
      // } else {
      //   this.$router.replace('/Sales')
      // }
      // ///////////////////////////////
      console.log(this.AuthorizationVersion)
      switch (this.AuthorizationVersion) {
        case 2: // JWT Tokken
          var RememberMe = this.$store.state.CookiesMemory.SignInRememberMe
          if (RememberMe) {
            this.LocalSave('CookiesUserName', this.Email.replace(/nbsp;/g, '').trim())
            // this.LocalSave('CookiesPass', this.Password.trim())
          } else {
            this.LocalSave('CookiesUserName', this.Email.replace(/nbsp;/g, '').trim())
          }
          this.Post('POST', 'Login', { Email: this.Email.replace(/nbsp;/g, '').trim(), Password: this.Password.trim() }).then((response) => {
            var ExpirationTime = new Date().getTime() + (this.BakingMinitues * 60 * 1000)
            this.Console(response)
            this.Console(this.DeepFetcher(response, 'AccessToken'))
            console.log('CookiesJWT')
            console.log(this.DeepFetcher(response, 'AccessToken'))
            if (RememberMe) {
              this.LocalSave('CookiesJWT', { value: this.DeepFetcher(response, 'AccessToken'), exp: ExpirationTime })
              this.LocalSave('CookiesBAKERY', { value: this.DeepFetcher(response, 'RefreshToken') })
            } else {
              this.SessionSave('CookiesJWT', { value: this.DeepFetcher(response, 'AccessToken'), exp: ExpirationTime })
              this.SessionSave('CookiesBAKERY', { value: this.DeepFetcher(response, 'RefreshToken') })
            }
            this.$emit('SignedIn')
          }, error => {
            console.log('sError')
            this.Console('errorrr')
            this.Console(error)
            handleErrors(error)
            function handleErrors (errortobehandeled: any) {
              _this.Console('error')
              _this.Console(errortobehandeled)
              _this.Console(errortobehandeled.errors)
              console.log(errortobehandeled)
              var Errors = errortobehandeled.errors
              if (Errors && Errors.UserName !== undefined) {
                _this.UserNameServerErrors = Errors.UserName[0]
              }
              if (Errors && Errors.Password !== undefined) {
                _this.PassServerErrors = Errors.Password[0]
              }
              _this.ErrorsVisibilty = true
              _this.ServerError = errortobehandeled.Message ? errortobehandeled.Message : errortobehandeled.message
            }
          })
          break
        case 1: // Cookies
          this.Post('POST', 'Login', { UserName: this.Email, Password: this.Password, RememberMe: this.$store.state.CookiesMemory.SignInRememberMe }).then(response => {
            this.$emit('SignedIn')
          }, error => {
            this.ErrorsVisibilty = true
            this.ServerError = error.Description
          })
          break
        default:
          break
      }
    },
    CloseMe () {
      if (this.Access(this.$route.meta.Access as AccessFeature)) {
        this.$store.state.Accessable = true
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3d50c7da": (_ctx.Theme.BackBlur),
  "cbc49130": (_ctx.Theme.BG2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__