import { withKeys as _withKeys, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bd43614"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name", "type"]
const _hoisted_2 = { class: "CookiesInputPlaceholder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.Placeholder === '' || (_ctx.MEMRead(_ctx.Res) === '' || !_ctx.MEMRead(_ctx.Res)) ? '' : 'padding-top: 0.95em; padding-bottom: 0.45em;'),
    class: _normalizeClass(["CookiesInput P-Rel",  [_ctx.ErrorData && ' ErrorBorder ', _ctx.Disabled && ' Disabled '] ])
  }, [
    _withDirectives(_createElementVNode("input", {
      onKeypress: _cache[0] || (_cache[0] = _withKeys(($event: any) => (($event.target as any)?.blur()), ["enter"])),
      name: _ctx.Name !== '' ? _ctx.Name : _ctx.Placeholder,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$store.state.CookiesMemory[_ctx.Res ? _ctx.Res : 'undefined']) = $event)),
      type: _ctx.type,
      autocomplete: "off"
    }, null, 40, _hoisted_1), [
      [_vModelDynamic, _ctx.$store.state.CookiesMemory[_ctx.Res ? _ctx.Res : 'undefined']]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "M-Auto",
        style: _normalizeStyle((_ctx.MEMRead(_ctx.Res) === '' || !_ctx.MEMRead(_ctx.Res)) ? 'margin-inline-start: 1.25em;' : 'margin-inline-start: 0.9em; margin-top: 0.2em; font-size: 0.75em; filter: blur(0.6px)')
      }, _toDisplayString(_ctx.Placeholder), 5)
    ])
  ], 6))
}