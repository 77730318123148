
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  name: 'System Config',
  data () {
    return {
      LanguageIndex: -1,
      selectedFile: null,
      OriginalConfig: {} as any,
      LanguagesArray: [] as Array<any>,
      Saving: false
    }
  },
  mounted () {
    const _this = this
    Object.keys(this.Languages).some(function (Lang) {
      _this.LanguagesArray.push({
        Name: _this.Languages[Lang].Name,
        Code: Lang
      })
    })
    this.LanguageIndex = this.IndexFinder(this.LanguagesArray, ['Code'], this.$store.state.CookiesConfig.Language)
    this.MEMWrite('SystemBackground', this.$store.state.CookiesConfig.background)
    this.MEMWrite('Config_LanguageUserSelect', this.$store.state.CookiesConfig.LanguageUserSelect)
    this.ReadConfig()
  },
  computed: {},
  methods: {}
})
