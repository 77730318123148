import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "P_75em"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesTabs = _resolveComponent("CookiesTabs")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.Tabs.length > 1)
      ? (_openBlock(), _createBlock(_component_CookiesTabs, {
          key: 0,
          Style: 'border-top-left-radius: 0; border-top-right-radius: 0; background: ' + _ctx.Theme.Shade3,
          Class: 'F_85em',
          TabsContainerWidth: '85%',
          Res: 'Sales',
          Tabs: _ctx.Tabs,
          Default: (_ctx.Tabs[0] as CookiesTabs)
        }, null, 8, ["Style", "Tabs", "Default"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.Translate(_ctx.Tabs[0].Name)), 1)),
    _createVNode(_component_router_view)
  ], 64))
}