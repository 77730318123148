
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Tabs: { default: () => {}, type: Object as PropType<Array<CookiesTabs>> },
    Router: { default: true, Type: Boolean },
    TabsContainerWidth: { default: '', Type: String },
    Class: { default: '', Type: String },
    Style: { default: '', Type: String },
    Default: { default: () => {}, type: Object as PropType<CookiesTabs> },
    RouteStrict: { default: true, Type: Boolean },
    Res: { default: '', Type: String }
  },
  data () {
    return {
      SelectedTab: {
        Name: ''
      } as CookiesTabs
    }
  },
  computed: {
    AccessableTabs () {
      var Routes = this.$router.getRoutes()
      return this.Tabs.filter(tab => {
        if (tab.Access !== undefined) {
          return tab.Access
        } else if (this.Router) {
          const MyRoute = Routes.find(route => {
            return route.path === tab.Link
          })
          if (MyRoute) {
            return this.Access(MyRoute.meta.Access as AccessFeature)
          } else {
            return true
          }
        } else {
          return true
        }
      })
    }
  },
  mounted () {
    this.SelectedTab = this.Default
    this.MEMWrite(this.Res, this.SelectedTab)
    this.MEMWrite('HeaderTabs_' + this.Res, this.SelectedTab)
  },
  methods: {
    Selected (tab :CookiesTabs) {
      this.$emit('selected', tab)
      this.SelectedTab = tab
      if (this.Res !== '') {
        this.MEMWrite(this.Res, this.SelectedTab)
      }
    },
    SelectedThroughMenu (tab :CookiesTabs) {
      if (this.Router && tab.Link) {
        this.$router.push(tab.Link)
      } else {
        this.Selected(tab)
      }
    }
  },
  watch: {
    SelectedTab: function (newVal, oldVal) {
      this.MEMRead('SelectedTab')
    },
    $route: function (newVal, oldVal) {
      if (!this.Router) {
        var NewTab = this.Tabs.find(tab => {
          return tab.Link === this.$route.path
        })
        if (NewTab) {
          this.SelectedTab = NewTab
        }
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "93b1924e": (_ctx.Theme.color3),
  "7b88fd79": (_ctx.Theme.BG2_75),
  "93b19252": (_ctx.Theme.color1),
  "6dc60f84": (_ctx.Theme.BG2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__