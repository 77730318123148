
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      Acc: 0,
      OriginalData: {} as Section,
      Saving: false,
      Transfertem: null as any,
      ItemsLocation: [] as any,
      Sections: [] as any,
      SavingTable: [] as Array<'Saving'>,
      Loading: [] as Array<'Loading'>,
      Saved: undefined as boolean|undefined,
      CookiesData: {} as any,
      TransferItems: [] as any,
      AvailableItems: [] as any,
      Items: [] as any,
      Brands: [] as any,
      Warehouses: [] as any,
      Categories: [] as any,
      CookiesSpecs: [] as any,
      TransferSources: [] as any,
      ErrorsVisibilty: false,
      CurrentImage: undefined as File | undefined,
      DialogMapper: [
        { Path: 'Items', Res: 'D_Transfer_Items', Default: [] },
        { Path: 'CustomerName', Res: 'D_Transfer_Customer', Default: '' },
        { Path: 'CustomerPhone', Res: 'D_Transfer_CustomerPhone', Default: '' },
        { Path: 'VehicleNumber', Res: 'D_Transfer_VehicleNumber', Default: '' },
        { Path: 'Type', Value: this.DialogData.Type },
        { Path: 'Notes', Res: 'D_Transfer_Note', Default: '' },
        { Path: 'SupplierID', Res: 'D_Transfers_ImportSource', ValuePath: 'ID', Default: null },
        { Path: 'ContainerShip', Res: 'D_Transfers_ContainerShip', Default: '' },
        { Path: 'SupplierName', Res: 'D_Transfers_SupplierName' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    ReadDialogData () {
      this.ReadItems()
      this.ReadTransferSources()
      this.ReadAvailableItems()
      this.ReadBrands()
      this.ReadWarehouses()
      this.ReadSections()
      this.GetLocations()
      this.ReadCategories()
    },
    ReadSections () {
      this.Loading.push('Loading')
      this.Get('Inventory_Sections').then(response => {
        this.Sections = response
        console.log(response)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Sections'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ShowLocations () {
      var ItemAval = this.DecodedAval.filter((item :any) => {
        return this.Transfertem.ID === item.ItemID && item.Type === 'Available'
      })
      this.ViewLocDialog(ItemAval, ItemAval[0].ItemID)
    },
    ViewLocDialog (ItemAval :any, ID: number) {
      this.OpenDialog('Inventory_AvailablItemLocations_Dialog', 'Locations', { Data: ItemAval, ItemID: ID }, { NoBackgroundClose: true, header: false })
    },
    ReadWarehouses () {
      this.Get('Inventory_Warehouses', '?ListAll=true').then(response => {
        this.Warehouses = response
      }, error => {
        error.CookiesError = 'Error in reading Warehouses'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    RemoveItem (Item :any) {
      this.TransferItems.splice(Item.OriginalIndex, 1)
    },
    ReadItems () {
      this.Loading.push('Loading')
      this.Get('Inventory_Items', '?ListAllWarehouses=true').then(response => {
        this.Items = this.GeneralSort(response, 'Code', 1)
        try {
          if (this.CookiesData && this.CookiesData.Items && this.CookiesData.Items.length > 0) {
            this.CookiesData.Items.forEach((transitem :any) => {
              var Found = this.Items.find((item:any) => {
                return item.ID === transitem.ItemID
              })
              if (Found) {
                Found.AvailableQuantity += transitem.Quantity
                this.TransferItems.push(this.DeepCloner({
                  ...Found,
                  ...transitem,
                  ResID: transitem.ItemID + '_' + this.Acc
                }))
                this.Acc += 1
              }
            })
          }
        } catch (error) {
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadAvailableItems () {
      this.Loading.push('Loading')
      this.Get('Inventory_AvailableItems', '?ListAllWarehouses=true').then(response => {
        this.AvailableItems = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCategories () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=Items').then(response => {
        this.Categories = this.GeneralSort(response, 'Name', 1)
        if (this.DialogData.Data && this.DialogData.Data.CategoryID) {
          var SelectedCategory = this.Categories.find((cat :any) => {
            return cat.ID === this.DialogData.Data.CategoryID
          })
          if (SelectedCategory) {
            this.MEMWrite('D_Item_Category_Value', SelectedCategory)
            this.MEMWrite('D_Item_Category', SelectedCategory.Name)
          }
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadBrands () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=Brands').then(response => {
        this.Brands = this.GeneralSort(response, 'Name', 1)
        if (this.DialogData.Data && this.DialogData.Data.BrandID) {
          var SelectedBrand = this.Brands.find((brand :any) => {
            return brand.ID === this.DialogData.Data.BrandID
          })
          if (SelectedBrand) {
            this.MEMWrite('D_Item_Brand_Value', SelectedBrand)
            this.MEMWrite('D_Item_Brand', SelectedBrand.Name)
          }
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadSpecs () {
      if (!this.DialogData.Editing) return
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=ItemSpecs_' + this.DialogData.Data.ID).then(response => {
        this.CookiesSpecs = response.map((element :any) => {
          element.TempID = element.ID
          return element
        })
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveData () {
      if (this.Saving) return
      this.Saving = true
      // if (this.DialogData.Editing) {
      //   var EditedItems :Array<any> = []
      //   EditedItems = this.TransferItems.map((item :any) => {
      //     return {
      //       ...item,
      //       ItemID: item.ID,
      //       ItemName: item.Name,
      //       ItemCode: item.Code,
      //       IsUserSelect: item.IsUserSelect,
      //       AvalID: item.AvalID,
      //       IsPack: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID),
      //       PackSize: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID) ? this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack Size' + '_' + item.ResID) : 1,
      //       Quantity: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Available' + '_' + item.ResID),
      //       Details: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Details' + '_' + item.ResID)
      //     }
      //   })
      //   var EditedSupplier = this.MEMRead('D_Transfers_ImportSource_Value')
      //   if (EditedSupplier && EditedSupplier.Name) {
      //     this.MEMWrite('D_Transfers_EditedSupplierName', EditedSupplier.Name)
      //   }
      //   this.MEMWrite('D_Transfer_Items', EditedItems)
      //   this.Post('PATCH', 'Inventory_Transfers', this.DialogDataDecoder(this.DialogMapper, true), '?ID=' + this.DialogData.Data.ID + '&Stat=' + this.DialogData.Type + 'Edit').then(() => {
      //     this.Saving = false
      //     this.$router.go(-1)
      //   }, error => {
      //     this.Saving = false
      //     error.CookiesError = 'Error in Editing Item'
      //     this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      //   })
      //   return
      // }
      // var SelectedItems :Array<any> = []
      // SelectedItems = this.TransferItems.map((item :any) => {
      //   return {
      //     ItemID: item.ID,
      //     ItemName: item.Name,
      //     ItemCode: item.Code,
      //     IsUserSelect: item.IsUserSelect,
      //     AvalID: item.AvalID,
      //     IsPack: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID),
      //     PackSize: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID) ? this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack Size' + '_' + item.ResID) : 1,
      //     Quantity: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Available' + '_' + item.ResID),
      //     Details: item.Details
      //   }
      // })
      var ReqBody :any = {
        ItemName: this.Transfertem.Name,
        ItemCode: this.Transfertem.Code,
        BranchID: this.$store.getters.BranchID,
        Notes: this.MEMRead('D_Transfer_Note')
      }
      ReqBody.From = this.MEMRead('D_InternalTransfer_Table').filter((element :any) => {
        return element.Total > 0
      })
      ReqBody.To = this.MEMRead('D_InternalTransferTo_Table').filter((element :any) => {
        return element.Total > 0
      })
      console.log(ReqBody)
      this.Post('POST', 'Inventory_Transfers_Internal', ReqBody).then(() => {
        this.Saving = false
        this.$router.go(-1)
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in Adding Transfer'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    CheckItem (Item: InventoryItem) {
      this.AddItem(Item)
    },
    AddItem (Item: InventoryItem) {
      if (Item.AvailableQuantity === 0) {
        this.MEMClean('D_Transfer_Item', true)
        return
      }
      this.Transfertem = Item
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    },
    EditCategories (Type: string, Name: string) {
      this.OpenDialog('Main_Categories_Dialog', this.Translate('Edit ' + Name), { Data: {}, Type: Type })
    },
    ReadTransferSources () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=TransferSources').then(response => {
        this.TransferSources = response
        console.log(this.CookiesData)
        if (this.CookiesData && this.CookiesData.SupplierID) {
          var SelectedSource = this.TransferSources.find((Source :any) => {
            return Source.ID === this.CookiesData.SupplierID
          })
          if (SelectedSource) {
            this.MEMWrite('D_Transfers_ImportSource_Value', SelectedSource)
            this.MEMWrite('D_Transfers_ImportSource', SelectedSource.Name)
          }
        } else {
          this.MEMClean('D_Transfers_ImportSource')
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Sources'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ExportTableSettings (item :any) {
      var Table = {
        TableName: item.Name + `( ${item.Code} )`,
        CookiesID: 'D_ExportTransfer_Table_' + item.ID,
        ResID: 'ID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Pack Size',
            Type: 'normal',
            Value: 'PackSize',
            Res_ID: 'ResID'
          },
          {
            Name: 'Available',
            Type: 'normal',
            Value: 'Quantity',
            Bold: true,
            Visible: this.DialogData.Type === 'Internal'
          },
          {
            Name: 'Quantity',
            Type: 'numberInput',
            numberInput_Default: 0,
            numberInput_Min: 0,
            Value: ['AddedQuantity'],
            Res_ID: 'ID',
            numberInput_Max: ['Quantity']
          },
          {
            Name: 'Total',
            Type: 'normal',
            Value: 'Total',
            Bold: true,
            Total: true
          }
        ],
        Footer: true,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'AddedDate'
        }
      } as CookiesTable
      return Table
    },
    GetLocations () {
      // if (!this.Transfertem || !this.MEMRead('D_Transfer_WarehouseFrom')) return
      this.Loading.push('Loading')
      this.Get('Inventory_ItemsLocation').then(response => {
        this.ItemsLocation = response
        // this.ReadItems()
        console.log(response)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  created () {
    this.CookiesData = this.DialogData.Data
  },
  mounted () {
    if (this.CookiesData) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.CookiesData) as Section
    }
    this.ReadDialogData()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_Transfer')
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('Items'),
        CookiesID: 'D_InternalTransfer_Table',
        ResID: 'ID',
        MultiLevel: true,
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Pack Size',
            Type: 'normal',
            Value: 'PackSize'
          },
          {
            Name: 'Available',
            Type: 'normal',
            Value: 'Quantity',
            Bold: true
          },
          {
            Name: 'Details',
            Type: 'normal',
            Value: 'Details'
          },
          {
            Name: 'Location',
            Type: 'normal',
            Value: 'DecodedLoc',
            Bold: true
          },
          {
            Name: 'Quantity',
            Type: 'numberInput',
            numberInput_Default: 0,
            numberInput_Min: 0,
            Value: ['AddedQuantity'],
            Res_ID: 'ID',
            numberInput_Max: ['Quantity']
          },
          {
            Name: 'Total',
            Type: 'normal',
            Value: 'Total',
            Bold: true,
            Total: true
          }
        ],
        Footer: true,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'Code'
        }
      } as CookiesTable
      return Table
    },
    ToTableSettings () {
      var Table = {
        TableName: this.Translate('Items'),
        CookiesID: 'D_InternalTransferTo_Table',
        ResID: 'ID',
        MultiLevel: true,
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Location',
            Type: 'normal',
            Value: 'DecodedLoc',
            Bold: true
          },
          {
            Name: 'Available Space',
            Type: 'normal',
            Value: 'AvailableSpace',
            Bold: true
          },
          {
            Name: 'Details',
            Type: 'normal',
            Value: 'Details',
            Editable: true,
            Res_ID: 'ID'
          },
          {
            Name: 'Pack Size',
            Type: 'numberInput',
            Value: 'PackSize',
            numberInput_Default: 1,
            Res_ID: 'ID',
            DynamicClass: {
              ClassList: 'Disabled',
              FixedValue: true,
              Operator: 'EQUAL',
              ValuePath: 'NoPackSize'
            }
          },
          {
            Name: 'Quantity',
            Type: 'numberInput',
            numberInput_Default: 0,
            numberInput_Min: 0,
            Value: ['AddedQuantity'],
            Res_ID: 'ID',
            numberInput_Max: ['AvailableSpace']
          },
          {
            Name: 'Total',
            Type: 'normal',
            Value: 'Total',
            Bold: true,
            Total: true
          }
        ],
        Footer: true,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'Code'
        }
      } as CookiesTable
      return Table
    },
    ComputedSelectors () {
      if (this.DialogData.Type === 'Internal') {
        return [['Code'], ['Name'], ['Specifications', 0, 'Description'], ['Specifications', 1, 'Description']]
      } else {
        return [['Code'], ['Name'], ['Specifications', 0, 'Description'], ['Specifications', 1, 'Description']]
      }
    },
    FilteredItems () {
      var Filteredd :any = []
      var IsSearch = this.MEMRead('D_Transfer_Search') && this.MEMRead('D_Transfer_Search').length > 2
      var IsBrand = this.MEMRead('D_Transfer_Brand') && this.MEMRead('D_Transfer_Brand').ID !== undefined
      var IsCategory = this.MEMRead('D_Transfer_Brand') && this.MEMRead('D_Transfer_Category').ID !== undefined
      var Items = this.Items
      var TransferItems = this.TransferItems
      Filteredd = Items.filter((item :any) => {
        var ItemSelected = false
        if (this.DialogData.Type === 'Internal') {
          ItemSelected = TransferItems.some((element :any) => {
            return item.ID === element.ID
          })
        }
        var Search = this.MEMRead('D_Transfer_Search') === '' || this.MEMRead('D_Transfer_Search').length < 3 || this.CookiesSearcher(item, this.MEMRead('D_Transfer_Search'))
        var Brand = !this.MEMRead('D_Transfer_Brand').ID || (this.MEMRead('D_Transfer_Brand').ID === item.BrandID)
        var Category = !this.MEMRead('D_Transfer_Category').ID || (this.MEMRead('D_Transfer_Category').ID === item.CategoryID)
        return (IsSearch || IsBrand || IsCategory) && !ItemSelected && Search && Brand && Category
      })
      return Filteredd
    },
    CashedTranslations () {
      return {
        Name: this.Translate('Name'),
        Brand: this.Translate('Brand'),
        Category: this.Translate('Category'),
        Available: this.Translate('Available'),
        'Not Available': this.Translate('Not Available'),
        Specs: this.Translate('Specs')
      }
    },
    AvailableItemsLocations () {
      if (!this.Transfertem) return []
      var available = this.DecodedAval.filter((item :any) => {
        return ((this.Transfertem.ID === item.ItemID) && (this.MEMRead('D_Transfer_WarehouseFrom_Value').ID === item.WarehouseID))
      })
      try {
        var availableMapped = available.map((element :any) => {
          var Total = 0
          Total = element.IsPack ? element.PackSize * this.MEMRead('D_InternalTransfer_Table_Edited_Quantity_' + element.ID) : this.MEMRead('D_InternalTransfer_Table_Edited_Quantity_' + element.ID)
          return {
            Total: Total,
            NoPackSize: !element.IsPack || element.IsUserSelect,
            ...element
          }
          // if (!element.PackSize) return element
          // if (!this.MEMRead('D_InternalTransfer_Table_' + element.ItemID + '_Edited_Quantity_' + element.ID)) return element
          // element.Total = element.PackSize * this.MEMRead('D_InternalTransfer_Table_' + element.ItemID + '_Edited_Quantity_' + element.ID)
          // return element
        })
      } catch (error) {
        console.log(error)
      }
      return availableMapped
    },
    TotalFrom () {
      if (!this.MEMRead('D_InternalTransfer_Table') || !Array.isArray(this.MEMRead('D_InternalTransfer_Table'))) return 0
      var Total = 0
      this.MEMRead('D_InternalTransfer_Table').forEach((element :any) => {
        Total += element.Total
      })
      return Total
    },
    TotalTo () {
      if (!this.MEMRead('D_InternalTransferTo_Table') || !Array.isArray(this.MEMRead('D_InternalTransferTo_Table'))) return 0
      var Total = 0
      this.MEMRead('D_InternalTransferTo_Table').forEach((element :any) => {
        Total += element.Total
      })
      return Total
    },
    DecodedAval () {
      return this.AvailableItems.map((item :any) => {
        item.DecodedLoc = (item.WarehouseName && item.WarehouseName !== '' ? item.WarehouseName : '') + `(${item.WarehouseCode})` + (item.SectionLevel1ID ? (' > ' + (item.Section1Name && item.Section1Name !== '' ? item.Section1Name : '') + `(${item.Section1Code})`) : '') + (item.SectionLevel2ID ? (' > ' + (item.Section2Name && item.Section2Name !== '' ? item.Section2Name : '') + `(${item.Section2Code})`) : '') + ' > ' + (item.SectionName && item.SectionName !== '' ? item.SectionName : '') + `(${item.SectionCode})`
        return item
      })
    },
    DecodedSections () {
      if (!this.Transfertem || !this.MEMRead('D_Transfer_WarehouseFrom') || !this.MEMRead('D_Transfer_WarehouseTo')) return
      var ItemLocations = this.ItemsLocation.filter((loc :any) => {
        return loc.ItemID === this.Transfertem.ID
      })
      var ItemLocationsDecoded :Array<any> = []
      ItemLocations.forEach((loc :any) => {
        var NewLoc = { ...loc }
        var Section = this.Sections.find((sec :any) => {
          return sec.ID === loc.SectionID && sec.WarehouseID === this.MEMRead('D_Transfer_WarehouseTo_Value').ID
        })
        if (!Section) return
        var Sectionlevel1 = null
        var Sectionlevel2 = null
        if (Section.SectionLevel1ID) {
          Sectionlevel1 = this.Sections.find((sec :any) => {
            return sec.ID === Section.SectionLevel1ID
          })
        }
        if (Section.SectionLevel2ID) {
          Sectionlevel2 = this.Sections.find((sec :any) => {
            return sec.ID === Section.SectionLevel2ID
          })
        }
        NewLoc.DecodedLoc = (Section.WarehouseName && Section.WarehouseName !== '' ? Section.WarehouseName : '') + `(${Section.WarehouseCode})` + (Sectionlevel1 ? (' > ' + (Sectionlevel1.Name ? Sectionlevel1.Name : '') + `(${Sectionlevel1.Code})`) : '') + (Sectionlevel2 ? (' > ' + (Sectionlevel2.Name ? Sectionlevel2.Name : '') + `(${Sectionlevel2.Code})`) : '') + ' > ' + (Section.Name && Section.Name !== '' ? Section.Name : '') + `(${Section.Code})`
        var Total = 0
        var AvailableSpace = loc.QuantityMax - loc.Quantity
        Total = loc.IsPack ? this.MEMRead('D_InternalTransferTo_Table_Edited_Pack Size_' + loc.ID) * this.MEMRead('D_InternalTransferTo_Table_Edited_Quantity_' + loc.ID) : this.MEMRead('D_InternalTransferTo_Table_Edited_Quantity_' + loc.ID)
        // NewLoc.Total = Total
        ItemLocationsDecoded.push({
          AvailableSpace: AvailableSpace,
          Total: Total,
          NoPackSize: !loc.IsPack || loc.IsUserSelect,
          Details: this.MEMRead('D_InternalTransferTo_Table_Edited_Details_' + loc.ID),
          ItemLocationID: loc.ID,
          ...NewLoc
        })
      })
      console.log(ItemLocationsDecoded)
      return ItemLocationsDecoded
    },
    TotalSense () {
      if ((this.TotalFrom === this.TotalTo) && this.TotalTo > 0) {
        return {
          Status: true,
          Color: ''
        }
      } else {
        return {
          Status: false,
          Color: 'var(--colorr1)'
        }
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "77aa537a": (_ctx.Theme.Shade1),
  "6bc949b1": (_ctx.Theme.Light1),
  "33df1c9a": (_ctx.Theme.BG5),
  "359af290": (_ctx.TotalSense.Color)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__