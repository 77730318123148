
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

import { getBase64Strings } from 'exif-rotate-js/lib'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { drawArbitraryQuadImage, FILL_METHOD } = require('canvas-arbitrary-quads')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { createCanvas } = require('canvas')
const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Res: { type: String as PropType<ObjectPath> },
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> },
    File: { type: String as PropType<ObjectPath> },
    Folder: { default: 'catalogs', type: String as PropType<ObjectPath> }
  },
  setup () {},
  data () {
    return {
      ImgURL: null as any,
      Grapping: false,
      ImageFile: null as any,
      CircleSize: 4,
      Saving: true,
      Cropper: {
        P1: {
          x: 10,
          y: 10
        },
        P2: {
          x: 90,
          y: 10
        },
        P3: {
          x: 90,
          y: 90
        },
        P4: {
          x: 10,
          y: 90
        }
      },
      OriginalImage: {
        Width: 100,
        Height: 100
      },
      GrappedPoints: [] as Array<{Ref: HTMLElement, OffsetX: number, OffsetY: number}>
    }
  },
  computed: {
    PolygonPoints () {
      return this.Cropper.P1.x + ',' + this.Cropper.P1.y + ' ' + this.Cropper.P2.x + ',' + this.Cropper.P2.y + ' ' + this.Cropper.P3.x + ',' + this.Cropper.P3.y + ' ' + this.Cropper.P4.x + ',' + this.Cropper.P4.y
    },
    ClipPath () {
      return this.Cropper.P1.x + '% ' + this.Cropper.P1.y + '%, ' + this.Cropper.P2.x + '% ' + this.Cropper.P2.y + '%, ' + this.Cropper.P3.x + '% ' + this.Cropper.P3.y + '%, ' + this.Cropper.P4.x + '% ' + this.Cropper.P4.y + '%, ' + this.Cropper.P1.x + '% ' + this.Cropper.P1.y + '%'
    },
    CropperLines () {
      return {
        LineA: {
          x1: (this.Cropper.P1.x + this.Cropper.P2.x) / 2,
          y1: (this.Cropper.P1.y + this.Cropper.P2.y) / 2,
          x2: (this.Cropper.P3.x + this.Cropper.P4.x) / 2,
          y2: (this.Cropper.P3.y + this.Cropper.P4.y) / 2
        },
        LineB: {
          x1: (this.Cropper.P1.x + this.Cropper.P4.x) / 2,
          y1: (this.Cropper.P1.y + this.Cropper.P4.y) / 2,
          x2: (this.Cropper.P2.x + this.Cropper.P3.x) / 2,
          y2: (this.Cropper.P2.y + this.Cropper.P3.y) / 2
        },
        Line12: {
          x1: this.Cropper.P1.x,
          y1: this.Cropper.P1.y,
          x2: this.Cropper.P2.x,
          y2: this.Cropper.P2.y
        },
        Line23: {
          x1: this.Cropper.P2.x,
          y1: this.Cropper.P2.y,
          x2: this.Cropper.P3.x,
          y2: this.Cropper.P3.y
        },
        Line34: {
          x1: this.Cropper.P3.x,
          y1: this.Cropper.P3.y,
          x2: this.Cropper.P4.x,
          y2: this.Cropper.P4.y
        },
        Line41: {
          x1: this.Cropper.P4.x,
          y1: this.Cropper.P4.y,
          x2: this.Cropper.P1.x,
          y2: this.Cropper.P1.y
        }
      }
    }
  },
  async created () {
    const dataURL = await getBase64Strings([this.DialogData.Data.File], { maxSize: 1504 })
    var newImg = new Image()
    const _this = this
    newImg.onload = function () {
      newImg.crossOrigin = 'Anonymous'
      _this.OriginalImage.Height = newImg.height
      _this.OriginalImage.Width = newImg.width
      _this.ImageFile = newImg
      _this.Saving = false
    }
    this.ImgURL = dataURL[0]
    newImg.src = this.ImgURL
  },
  methods: {
    pointgrapped (Ref :string, OffsetX = 0, OffsetY = 0) {
      this.Grapping = true
      this.GrappedPoints.push({
        Ref: this.$refs['PrespectiveCropPoint' + Ref],
        OffsetX: OffsetX,
        OffsetY: OffsetY
      })
    },
    MultiPointGrapping (ArrayOfPoints :Array<string>) {
      ArrayOfPoints.forEach(point => {
        var ev :any = event
        var pp = this.$refs['PrespectiveCropPoint' + point]
        var xoffset = pp.getBoundingClientRect().x - (ev?.clientX)
        var yoffset = pp.getBoundingClientRect().y - (ev?.clientY)
        this.pointgrapped(point, xoffset, yoffset)
      })
    },
    MultiPointTouched (ArrayOfPoints :Array<string>) {
      ArrayOfPoints.forEach(point => {
        var ev :any = event
        var pp = this.$refs['PrespectiveCropPoint' + point]
        var xoffset = pp.getBoundingClientRect().x - (ev?.changedTouches[0].clientX)
        var yoffset = pp.getBoundingClientRect().y - (ev?.changedTouches[0].clientY)
        this.pointgrapped(point, xoffset, yoffset)
      })
    },
    StopGrapping () {
      this.Grapping = false
      setTimeout(() => {
        if (!this.Grapping) {
          this.GrappedPoints = []
          // this.CropIt()
        }
      }, 60)
    },
    CropIt () {
      if (this.Saving) {
        return
      }
      this.Saving = true
      var ComputedWidth = (this.Cropper.P2.x - this.Cropper.P1.x + this.Cropper.P3.x - this.Cropper.P4.x) / 200 * this.OriginalImage.Width
      var ComputedHeight = (this.Cropper.P4.y - this.Cropper.P1.y + this.Cropper.P3.y - this.Cropper.P2.y) / 200 * this.OriginalImage.Height
      var CropWidth = 0
      var CropHeight = 0
      if (ComputedHeight < ComputedWidth) {
        CropWidth = Math.min(ComputedWidth, 1500)
        CropHeight = ComputedHeight / ComputedWidth * CropWidth
      } else {
        CropHeight = Math.min(ComputedHeight, 1500)
        CropWidth = ComputedWidth / ComputedHeight * CropHeight
      }
      const srcPoints = [
        { x: this.Cropper.P1.x * this.OriginalImage.Width / 100, y: this.Cropper.P1.y * this.OriginalImage.Height / 100 },
        { x: this.Cropper.P4.x * this.OriginalImage.Width / 100, y: this.Cropper.P4.y * this.OriginalImage.Height / 100 },
        { x: this.Cropper.P3.x * this.OriginalImage.Width / 100, y: this.Cropper.P3.y * this.OriginalImage.Height / 100 },
        { x: this.Cropper.P2.x * this.OriginalImage.Width / 100, y: this.Cropper.P2.y * this.OriginalImage.Height / 100 }
      ]
      this.Console(srcPoints)
      const canvas = createCanvas(CropWidth, CropHeight, '2d')
      const ctx = canvas.getContext('2d')
      const dstPoints = [
        { x: 0, y: 0 },
        { x: 0, y: CropHeight },
        { x: CropWidth, y: CropHeight },
        { x: CropWidth, y: 0 }
      ]
      drawArbitraryQuadImage(ctx, this.ImageFile, srcPoints, dstPoints, FILL_METHOD.BILINEAR)
      this.PostImage(canvas.toDataURL()).then((response :any) => {
        this.MEMWrite(this.DialogData.Data.Res, response)
        this.$router.go(-1)
      }, error => {
        console.log(error)
        this.Saving = false
        error.CookiesError = 'Error in Uploading Image'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
      // ////////////
    },
    SkipCropping () {
      this.Saving = true
      this.PostImage(this.DialogData.Data.File).then((response :any) => {
        this.MEMWrite(this.DialogData.Data.Res, response)
        this.$router.go(-1)
      }, error => {
        this.Saving = false
        console.log(error)
        this.Saving = false
        error.CookiesError = 'Error in Uploading Image'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    IsMoving (event :any) {
      if (this.GrappedPoints.length > 0) {
        this.GrappedPoints.forEach(point => {
          var PN = 'P' + point.Ref.id.slice(-1)
          var ImagRect = this.$refs.prespectiveWindow.getBoundingClientRect()
          var NewP = {
            x: Math.round(Math.min(Math.max((event?.clientX - ImagRect.left + point.OffsetX) / ImagRect.width * 1000, 0), 1000)) / 10,
            y: Math.round(Math.min(Math.max((event?.clientY - ImagRect.top + point.OffsetY) / ImagRect.height * 1000, 0), 1000)) / 10
          }
          this.Console(NewP)
          this.DeepChanger(this.Cropper, PN, NewP)
        })
      }
    },
    IsTouching (event :any) {
      if (this.GrappedPoints.length > 0) {
        this.GrappedPoints.forEach(point => {
          var PN = 'P' + point.Ref.id.slice(-1)
          var ImagRect = this.$refs.prespectiveWindow.getBoundingClientRect()
          var NewP = {
            x: Math.round(Math.min(Math.max((event?.changedTouches[0].clientX - ImagRect.left + point.OffsetX) / ImagRect.width * 1000, 0), 1000)) / 10,
            y: Math.round(Math.min(Math.max((event?.changedTouches[0].clientY - ImagRect.top + point.OffsetY) / ImagRect.height * 1000, 0), 1000)) / 10
          }
          this.Console(NewP)
          this.DeepChanger(this.Cropper, PN, NewP)
        })
      }
    },
    Cancel () {
      this.MEMWrite(this.DialogData.Data.Res, '')
      this.$router.go(-1)
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "00057b8e": (_ctx.CircleSize * 4 + "px"),
  "3f50194a": (_ctx.Theme.UserColors.main_75),
  "74558ac4": (_ctx.Theme.UserColors.third_25),
  "0c422f28": (_ctx.CircleSize * -2 + "px")
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__