const API_MAPPER :APIMAPPER = {
  // ---------
  // ==================================================================== GENERAL APIs
  Systems: 'Systems',
  // ---------
  Config: 'Config',
  // ----------------------------------------------------Categories
  Categories: 'Category',
  // ----------------------------------------------------Branches
  Branches: 'Branches',
  // ----------------------------------------------------
  // ---------
  // ==================================================================== Dashboard APIs
  DashBoard: 'DashBoard/Main',
  DashBoard_Transfers: 'DashBoard/TransfersCount',
  // ==================================================================== Security APIs
  // >>>> AuthorizationVersion [[ 2 ]] >>>>>>>>>>>>>>>>>>>>>>>>>>>>
  Authorization: 'Order',
  Login: 'User/login',
  Refresh: 'User/refresh-token',
  Logout: 'Authorization/Logout',
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  // >>>> AuthorizationVersion [[ 1 ]] >>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // Authorization: 'Users/Getuserauthorization',
  // Login: 'Users/Login',
  // Logout: 'Users/LogOut',
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  // ----------------------------------------------------Profiles
  Security_Profiles: 'Profiles',
  // ----------------------------------------------------Users
  Security_Users: 'User',
  Security_ChangePassword: 'User/ChangePassword',
  // ----------------------------------------------------Roles
  Security_Roles: 'Roles',
  // ---------
  // ----------------------------------------------------Employees
  HR_Employees: 'Employees',
  // ==================================================================== Category APIs
  Catalogs: 'Catalog',
  // ==================================================================== Inventory APIs
  // ----------------------------------------------------Items
  Inventory_Items: 'Items',
  Inventory_Items_MassADD: 'Items/MassCreate',
  // ----------------------------------------------------Warehouses
  Inventory_Warehouses: 'Warehouses',
  // -------------------------------------------------
  // ----------------------------------------------------Warehouses
  Inventory_Sections: 'Sections',
  // ----------------------------------------------------Warehouses
  Inventory_ItemsLocation: 'ItemsLocations',
  // ----------------------------------------------------Transfers
  Inventory_Transfers: 'Transfers',
  Inventory_Transfers_Import: 'Transfers/Import',
  Inventory_Transfers_ImportLocations: 'Transfers/ImportLocations',
  Inventory_Transfers_PartialImport: 'Transfers/PartialImport',
  Inventory_AvailableItems: 'AvailableItems',
  Inventory_TransferItems: 'TransfersItems',
  Inventory_Transfers_Export: 'Transfers/Export',
  Inventory_Transfers_BranchesTransfer: 'Transfers/BranchesTransfer',
  Inventory_Transfers_Internal: 'Transfers/InternalTransfer',
  Inventory_Transfers_UPDATE: 'Transfers/Update',
  // ----------------------------------------------------Units
  Inventory_Units: 'Units',
  // ----------------------------------------------------Categories
  Inventory_Categories: 'Categories',
  // ==================================================================== Sales APIs
  // ----------------------------------------------------Sales
  Sales_Orders: 'Order',
  // ==================================================================== CRM APIs
  // ----------------------------------------------------Customers
  CRM_Customers: 'Customer',
  // ----------------------------------------------------Customer Actions
  CRM_Customers_Actions: 'Customers/Actions',
  // ----------------------------------------------------Customer Visits
  CRM_Customers_Visits: 'Customers/Visits',
  // ==================================================================== Purchase APIs
  // ----------------------------------------------------Customers
  Purchase_Orders: 'Order',
  // ----------------------------------------------------Customer Actions
  Purchase_Vendors: 'Customer',
  // ==================================================================== MRP APIs
  // ----------------------------------------------------Manufacturers
  MRP_Manufacturers: 'Manufacturers',
  // ----------------------------------------------------Manufacturing Orders
  MRP_ManufacturingOrders: 'ManufacturingOrders',
  // ----------------------------------------------------Suppliers
  MRP_Suppliers: 'Suppliers',
  // ==================================================================== Finance APIs
  // ----------------------------------------------------Transactions
  Finance_Transactions: 'Payment',
  // ----------------------------------------------------Accounts
  Finance_Accounts: 'Accounts',
  // ----------------------------------------------------Invoices
  Finance_Invoices: 'Invoices',
  // ----------------------------------------------------Invoices
  Finance_Taxes: 'Taxes'
}

export const APIMapper = {
  computed: {
    API_URL () {
      if (window.location.origin.indexOf('http://localhost:') === -1) {
        return window.location.origin + '/'
      }
      // return 'https://94.127.214.84:8089/' // Delivery
      // return 'https://94.127.214.84:8091/' // testing
      // return 'https://94.127.214.84:9011/' // Main Dev
      // return 'https://94.127.214.84:9011/securitySystem/' // Security Dev
      // return 'https://103.125.216.197:9011/' // Security Dev
      // return 'https://146.71.78.183:9011/' // vpsserver
      // return 'http://localhost:9893/' // LocalExpressJS
      // return 'https://saedy.cookiesbis.com/' // Saedy Live
      // return 'http://137.184.25.226:9762/' // ExpressJS Test
      // return 'https://inventory.cookiesbis.com/' // Saedy Test
      // return 'https://catalogs.cookiesbis.com/api/' // <3,
      // return 'https://aylacatalogue.cookiesbis.com/' // New .netcore,
      return 'https://system.abudari.com/' // abudari crushers,
      // return 'http://165.232.123.252:5000/' // abudari crushers,
    },
    API_MAPPER () :APIMAPPER {
      var Mapper :any = {}
      const ApiMapper :any = API_MAPPER
      Object.keys(API_MAPPER).forEach((value) => {
        Mapper[value] = '' + ApiMapper[value] as any
      })
      return Mapper
    }
  }
}
