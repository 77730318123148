
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    Tabs: { default () { return [] }, type: Array as PropType<Array<CookiesTabs>> },
    ViewTypes: { default () { return [] }, type: Array as PropType<Array<any>> },
    Name: { default: '', type: String }
  },
  data () {
    return {
      ShowFilter: false,
      Search: '',
      Searching: false,
      PhoneSearchEnabled: false,
      Buttons: [] as Array<FunctionalTableButton>,
      Filter: undefined as FilterObject | undefined
    }
  },
  computed: {
    RouteName () {
      return this.$route.name
    },
    AccessableTabs () {
      const _this :any = this
      const OriginalTabs :Array<CookiesTabs> = _this.Tabs
      return OriginalTabs.filter(tab => {
        return tab.Access === undefined ? true : tab.Access
      })
    },
    This () {
      return this
    },
    TableButtonsPusher () {
      var AccessableButtons = this.Buttons.filter(button => {
        return button.Visible
      })
      return AccessableButtons.length > 0 ? '3.8em' : ''
    },
    TableButtonsPusherMobile () {
      var AccessableButtons = this.Buttons.filter(button => {
        return button.Visible
      })
      return AccessableButtons.length > 0 ? '5.5em' : ''
    }
  },
  // ---------------------------------------------------
  methods: {
    OpenSearch () {
      this.PhoneSearchEnabled = !this.PhoneSearchEnabled
      setTimeout(() => {
        this.$refs.HeaderSearch.focus()
      }, 200)
    },
    FocusOut () {
      setTimeout(() => {
        if (!this.Searching) {
          this.PhoneSearchEnabled = false
        }
      }, 250)
    },
    RemoveSearch () {
      this.Searching = true
      this.Search = ''
      this.Searching = false
    }
  },
  // ---------------------------------------------------
  beforeCreate () {},
  created () {
    var ArrayofPaths = this.AttributeArray(this.AccessableTabs, 'Link')
    var currentPath = this.$route.fullPath.split('/').pop()
    if (this.AccessableTabs.length > 0 && this.AccessableTabs[0].Link && currentPath && !(ArrayofPaths.indexOf(currentPath) > -1)) {
      this.$router.push(this.$route.path + '/' + this.AccessableTabs[0].Link)
    }
  },
  beforeMount () {},
  mounted () {},
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    RouteName: function () { // watch it
      this.Buttons = []
      var ArrayofPaths = this.AttributeArray(this.AccessableTabs, 'Link')
      var currentPath = this.$route.fullPath.split('/').pop()
      if (this.AccessableTabs.length > 0 && this.AccessableTabs[0].Link && currentPath && !(ArrayofPaths.indexOf(currentPath) > -1)) {
        this.$router.push(this.$route.path + '/' + this.AccessableTabs[0].Link)
      }
      this.Filter = undefined
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3292e21d": (_ctx.TableButtonsPusher),
  "4525951f": (_ctx.TableButtonsPusherMobile)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__