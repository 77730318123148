<template>
<div class="CookiesCardFill color3">
  <div class="M-Auto CookiesCardCentered">
    <div class="NumbersBar F1_25em F-Light EvenContainer">
      <div class="NumbersBarItem D-Flex BG1"> <!-- Total Number -->
        <div class="Total F_75em F-Bold O-75 NumberTitle">{{Translate('Total')}}</div>
        <div class="M-Auto W-100 H-100 P-Abs Inset0 D-Flex">
          <div class="M-Auto F3em F-Light colorFourth" style="">{{TotalOrders}}</div>
        </div>
      </div>
      <template v-for="status in ['Export', 'BranchesTransfer', 'Import']" :key="status">
        <div class="NumbersBarItem D-Flex LeftVSep">
          <div class=" F_75em F-Bold O-50 NumberTitle">{{Translate(statusText[status])}}</div>
          <CookiesIcon class="F-Bold M-Auto O-50" style="width: 1.5em; height: 1.5em; margin-bottom: 0.2em;" :ID="statusIcons[status]"></CookiesIcon>
          <div class="M-Auto W-100 H-100 P-Abs Inset0 D-Flex FlexColumn">
            <div class="M-Auto F3em F-ExtraLight">{{StatusCounter(status)}}</div>
          </div>
        </div>
      </template>
    </div>
    <div class="F1em F-Light EvenContainer M-Auto" style="align-items: center; width: 90%; justify-content: center; margin-top: 0.5em;">
      <div class="D-Flex color3" style="justify-content: center; justify-items: center; align-items: center; gap: 0.5em;"> <!-- Total Number -->
        <!-- <CookiesIcon class="SQR1em" :ID="'Add'"></CookiesIcon> -->
        <span class="F-Bold color3">{{Translate('New')}}</span>
      </div>
      <template v-for="status in ['Export', 'BranchesTransfer', 'Import']" :key="status">
        <div class="NumbersBarItem D-Flex H-100" style="justify-content: center; align-items: center;">
          <div class="M-Auto W-100 H-100 D-Flex FlexColumn">
            <div class="M-Auto F-ExtraLight">{{StatusCounter(status, ['New'])}}</div>
          </div>
        </div>
      </template>
    </div>
    <div class="F1em F-Light EvenContainer M-Auto colorr1" style="align-items: center; width: 90%; justify-content: center;">
      <div class="D-Flex color3" style="justify-content: center; justify-items: center; align-items: center; gap: 0.5em;"> <!-- Total Number -->
        <!-- <CookiesIcon class="SQR1em" :ID="'Removed'"></CookiesIcon> -->
        <span class="F-Bold colorr1">{{Translate('Canceled')}}</span>
      </div>
      <template v-for="status in ['Export', 'BranchesTransfer', 'Import']" :key="status">
        <div class="NumbersBarItem D-Flex H-100" style="justify-content: center; align-items: center;">
          <div class="M-Auto W-100 H-100 D-Flex FlexColumn">
            <div class="M-Auto F-ExtraLight">{{StatusCounter(status, ['Canceled'])}}</div>
          </div>
        </div>
      </template>
    </div>
    <div class="F1em F-Light EvenContainer M-Auto colorg3" style="align-items: center; width: 90%; justify-content: center;">
      <div class="D-Flex color3" style="justify-content: center; justify-items: center; align-items: center; gap: 0.5em;"> <!-- Total Number -->
        <!-- <CookiesIcon class="SQR1em" :ID="'Done'"></CookiesIcon> -->
        <span class="F-Bold colorg3">{{Translate('Done')}}</span>
      </div>
      <template v-for="status in ['Export', 'BranchesTransfer', 'Import']" :key="status">
        <div class="NumbersBarItem D-Flex H-100" style="justify-content: center; align-items: center;">
          <div class="M-Auto W-100 H-100 D-Flex FlexColumn">
            <div class="M-Auto F-ExtraLight">{{StatusCounter(status, ['Done'])}}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      CookiesDataOrders: [],
      Today: true,
      statusIcons: {
        Export: 'Export',
        Import: 'ItemImport',
        BranchesTransfer: 'Leveling'
      },
      statusText: {
        Export: 'Export',
        Import: 'Import',
        BranchesTransfer: 'Branches Transfer'
      }
    }
  },
  mounted () {
    this.ReadOrders()
  },
  computed: {
    TotalOrders () {
      try {
        return this.CookiesDataOrders.length
      } catch (error) {
        console.log(error)
        return 'N/A'
      }
    },
    Received () {
      try {
        var ReceivedCounter = 0
        this.CookiesDataOrders.forEach(element => {
          if (element.Type === 'Export') {
            ReceivedCounter++
          }
        })
        return ReceivedCounter
      } catch (error) {
        console.log(error)
        return 'N/A'
      }
    },
    OnDelivery () {
      try {
        var OnDeliveryCounter = 0
        this.CookiesDataOrders.forEach(element => {
          if (element.Type === 'OnDelivery') {
            OnDeliveryCounter++
          }
        })
        return OnDeliveryCounter
      } catch (error) {
        console.log(error)
        return 'N/A'
      }
    },
    Delivered () {
      try {
        var DeliveredCounter = 0
        this.CookiesDataOrders.forEach(element => {
          if (element.Type === 'Delivered') {
            DeliveredCounter++
          }
        })
        return DeliveredCounter
      } catch (error) {
        console.log(error)
        return 'N/A'
      }
    }
  },
  methods: {
    ReadOrders () {
      this.Get('Inventory_Transfers', '?Type=[Export,BranchesTransfer,Import,ExternalImport]' + (this.MEMRead('CurrentWarehouse') ? '&WarehouseID=' + this.MEMRead('CurrentWarehouse').IDs : '') + '&' + this.DateAPIURL()).then(response => {
        this.CookiesDataOrders = response
      }, error => {
        error.CookiesError = 'Error in reading Transfers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    NewOrder () {
      this.OpenDialog('NewOrder', this.LanguageSelector('New Order', 'طلب جديد'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    AddNewTrans () {
      this.OpenDialog('AddNewTrans', 'New Transaction') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    StatusCounter (status, stat = ['Done', 'New']) {
      try {
        var search = []
        if (status === 'Export') search = ['Export']
        if (status === 'BranchesTransfer') search = ['BranchesTransfer']
        if (status === 'Import') search = ['Import', 'ExternalImport']
        var Counter = 0
        this.CookiesDataOrders.forEach(element => {
          if (search.indexOf(element.Type) > -1 && stat.indexOf(element.Status) > -1) {
            Counter++
          }
        })
        return Counter
      } catch (error) {
        console.log(error)
        return 'N/A'
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadOrders()
    },
    CurrentDate: function (newVal, oldVal) { // watch it
      this.ReadOrders()
    }
  }
}
</script>

<style scoped>
.CookiesCardFill{
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}
.CookiesCardCentered{
  padding: 0.3em;
  height: 80%;
  width: 100%;
  position: relative;
}
.NumbersBar{
  width: 90%;
  background: var(--shade1);
  margin: auto;
  height: 65%;
  border-radius: var(--rad2);
  justify-content: center;
  overflow: hidden;
}
.CookieesNewLine{
  width: 100%;
}
.NumbersBarItem{
  height: 100%;
  /* vertical-align: middle; */
  display: flex;
  flex-direction: column;
  /* line-height: 1.4em; */
  position: relative;
  /* justify-content: center; */
}
.NumberTitle{
  margin-top: 0.2em;
}

.LeftVSep{
  border-left: solid 1px var(--shade1);
  margin-left: -1px;
}
@media (max-width: 700) {
  .NumbersBarItem{
    font-size: 2vw !important;
  }
  .CookiesButton{
    font-size: 1em !important;
  }
}
@media (max-width: 1200) {
  .NumbersBarItem{
    font-size: 0.4em !important;
  }
  .CookiesButton{
    font-size: 1em !important;
  }
}
.ToggleSelected{
  background: var(--white);
  opacity: 0.9;
}
.ToggleDeSelected{
  background: var(--wshade3);
  opacity: 0.4;
  color: var(--wshade3);
}
.NumberIcon{
  position: absolute;
  height:1.5em;
  z-index:-5;
}
[Tooltip]{
  z-index:10;
}
</style>
