
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {},
  data () {
    return {}
  },
  computed: {
    Name () {
      return this.Translate('Finance')
    },
    Tabs () {
      return [
        { Name: this.Translate('Collect'), Link: 'InTransactions', Access: this.Access('Finance_Transactions_Read') },
        { Name: this.Translate('Pay'), Link: 'OutTransactions', Access: this.Access('Finance_Transactions_Read') }
      ] as Array<CookiesTabs>
    }
  },
  methods: {},
  watch: {}
})
