<template>
<div @focusout="Opened = false" @blur="Opened = false" @click="Opened = !Opened" :style="[StyledOut, TextAlign, Opened? ' z-index: 30;': '']" :class="Disabled ? 'DropDownDisabled' : ''" class="CookiesSelectBotton">
  <img v-if="(Icons.length !== 0 & SelectedImage !== '')" class="SelectedIcon" :src="SelectedImage">
  <div v-if="(CookiesIcons.length !== 0 ) & SelectedIcon !== ''" class="SelectedIcon" :style="$store.getters.Direction === 'ltr' && 'order: 10;'"><CookiesIcon class="Icons" :ID="SelectedIcon"></CookiesIcon></div>
  <span class="F_75em" v-if="Name !== ''">{{Name + '('}} </span>
  <strong>{{Translate(SelectedName)}}</strong>
  <span class="F_75em" v-if="Name !== ''">{{')'}} </span>
  <div :style="[{background: StyledOut.background, 'max-height': MaxHeight}, TextAlign,]" class="CookiesSelectMenu" :class="Opened ? 'CookiesSelectMenuOpened': ''">
    <div @click="OptionSelected(option, Index, $event)" v-for="(option, Index) in ALLOptions" :key="(option, Index)" class="CookiesSelectItem" :class="HoverDark && 'DarkHover'">
      <div style="TextAlign" class="CookiesSelectRow H-100" :style="TextAlign">
        <div v-if="(CookiesIcons.length !== 0) & ALLCookiesIcons[Index] !== ''"  class="ImageList"><CookiesIcon class="Icons" :ID="[ALLCookiesIcons[Index]]"></CookiesIcon></div>
        <img v-if="(Icons.length !== 0 & ALLIcons[Index] !== '')" class="ImageList" :src="[ALLIcons[Index]]">
        <div class="CookiesSelectHead">
          <span v-if="Type === 'String'" :style="TextAlign">{{Translate(option)}}</span>
          <span v-if="Type === 'Object'" :style="TextAlign">{{Translate(DeepFetcher(option, SelectorFix))}}</span>
        </div>
      </div>
    </div>
  </div>
  <img class="CookiesDropDownIndicator" :style="Opened? 'transform: rotate(180deg);' : ''" src="@/assets/buttons/DownArrow.svg">
</div>
</template>

<script>
export default {
  props: {
    Width: { default: '', type: String },
    Height: { default: '', type: String },
    Background: { default: '', type: String },
    Align: { default: 'Center', type: String },
    Options: { default: [] },
    Selector: { default: 'Name' },
    Type: { default: 'String', type: String },
    Res: { default: 'String', type: String },
    Radio: { default: false, type: Boolean },
    Disabled: { default: false, type: Boolean },
    Icons: { default: [] },
    CookiesIcons: { default: [] },
    Name: { default: '' },
    HoverDark: { default: false },
    MaxHeight: { default: '16em', type: String },
    TrueValue: { default: [] },
    IndexSelect: { default: 0, type: Number }
  },
  data () {
    return {
      Opened: false,
      SelectedImage: '',
      SelectedIcon: '',
      CurrentIndex: 0
    }
  },
  mounted () {
    var selectedIndex = 0
    if (this.$store.state.CookiesMemory[this.Res]) {
      if (this.Type === 'String') {
        var Found = this.ALLOptions.findIndex((value) => { return value === this.$store.state.CookiesMemory[this.Res] })
        if (Found > -1) {
          selectedIndex = Found
        }
      } else {
        var Found2 = this.ALLOptions.findIndex((value) => { return this.DeepFetcher(value, this.SelectorFix) === this.$store.state.CookiesMemory[this.Res] })
        if (Found2 > -1) {
          selectedIndex = Found
        }
      }
    }
    this.$store.state.CookiesMemory[this.Res] = this.ALLOptions[selectedIndex]
    this.SelectedImage = this.ALLIcons[selectedIndex]
    this.SelectedIcon = this.ALLCookiesIcons[selectedIndex]
  },
  methods: {
    OptionSelected (option, Index, event) {
      var selected = option
      this.CurrentIndex = Index
      if (this.TrueValue.length > 0) {
        selected = this.TrueValue[Index]
      }
      this.$store.state.CookiesMemory[this.Res] = selected
      this.SelectedImage = this.ALLIcons[Index]
      this.SelectedIcon = this.ALLCookiesIcons[Index]
      this.$emit('slection_changed', selected)
      this.$emit('selected', selected)
    }
  },
  computed: {
    ALLOptions () {
      if (this.Radio) {
        return this.Options
      } else if (this.Type === 'Object') {
        // var newObj = [{}].concat(this.Options)
        var newObj = {}
        var NewArray = []
        this.DeepChanger(newObj, this.SelectorFix, this.LanguageSelector('All', 'الكل'))
        NewArray.push(newObj)
        NewArray = NewArray.concat(this.Options)
        // newObj[0][this.Selector] = 'All'
        return NewArray
      }
      return [this.LanguageSelector('All', 'الكل')].concat(this.Options)
    },
    ALLIcons () {
      if (this.Radio) {
        return this.Icons
      }
      return [''].concat(this.Icons)
    },
    ALLCookiesIcons () {
      if (this.Radio) {
        return this.CookiesIcons
      }
      return [''].concat(this.CookiesIcons)
    },
    StyledOut () {
      var BGG
      if (this.Background !== '') {
        BGG = this.Background
      } else {
        BGG = this.Theme.BG6
      }
      return {
        width: this.Width,
        height: this.Height,
        background: BGG
      }
    },
    Selected () {
      var x = {}
      if (this.TrueValue.length > 0) {
        x = this.Options[this.CurrentIndex]
      } else {
        x = this.$store.state.CookiesMemory[this.Res]
      }
      return x
    },
    TextAlign () {
      if (this.Align === 'Left') {
        return { textAlign: 'left', justifyContent: 'left' }
      }
      if (this.Align === 'Right') {
        return { textAlign: 'right', justifyContent: 'right' }
      }
      if (this.Align === 'Center') {
        return { textAlign: 'center', justifyContent: 'center' }
      } else {
        return {}
      }
    },
    SelectedName () {
      if (this.Type === 'Object') {
        try {
          return this.DeepFetcher(this.Selected, this.SelectorFix)
        } catch (error) {
          return ''
        }
      }
      return this.Selected
    },
    SelectorFix () {
      if (typeof this.Selector === 'string') {
        return [this.Selector]
      }
      return this.Selector
    }
  },
  watch: {
    Selected: function (newVal, oldVal) { // watch it
      this.SelectedImage = this.ALLIcons[this.ALLOptions.indexOf(newVal)]
      this.SelectedIcon = this.ALLCookiesIcons[this.ALLOptions.indexOf(newVal)]
      if (this.Type === 'Object') {
        try {
          var Path = this.Selector
          if (typeof this.Selector === 'string') {
            Path = [this.Selector]
          }
          this.SelectedIcon = this.ALLCookiesIcons[this.IndexFinder(this.ALLOptions, Path, newVal[this.Selector])]
        } catch (error) {}
      }
    },
    Options: function (newVal, oldVal) { // watch it
      try {
        if (this.Selected === undefined || !(this.Options.indexOf(this.Selected))) {
          var selectedIndex = 0
          if (this.$store.state.CookiesMemory[this.Res]) {
            if (this.Type === 'String') {
              var Found = this.ALLOptions.findIndex((value) => { return value === this.$store.state.CookiesMemory[this.Res] })
              if (Found > -1) {
                selectedIndex = Found
              }
            } else {
              var Found2 = this.ALLOptions.findIndex((value) => { return this.DeepFetcher(value, this.SelectorFix) === this.$store.state.CookiesMemory[this.Res] })
              if (Found2 > -1) {
                selectedIndex = Found
              }
            }
          }
          this.$store.state.CookiesMemory[this.Res] = this.ALLOptions[selectedIndex]
          this.SelectedImage = this.ALLIcons[selectedIndex]
          this.SelectedIcon = this.ALLCookiesIcons[selectedIndex]
          this.$emit('slection_changed', this.$store.state.CookiesMemory[this.Res])
          // this.$emit('selected', this.$store.state.CookiesMemory[this.Res])
        }
      } catch (error) {
        console.log(error)
      }
    },
    IndexSelect: function (newVal, oldVal) { // watch it
      this.$store.state.CookiesMemory[this.Res] = this.ALLOptions[this.IndexSelect]
    }
  }
}
</script>

<style scoped>
.CookiesSelectBotton{
  backdrop-filter: v-bind('Theme.BackBlur');
  border: none;
  border-radius: var(--rad2);
  flex: auto;
  padding: 0.7em 1.2em;
  outline: none;
  font-size: 1em;
  color: v-bind('Theme.color2');
  margin: 0.5em;
  cursor: pointer;
  position: relative;
  filter: drop-shadow(var(--shadow2));
  align-items: center;
  display: flex;
  /* height: 1.8em; */
}
.CookiesSelectMenuOpened{
  display: block !important;
  z-index: 15 !important;
  overflow-y: scroll;
  background: v-bind('Theme.BG6Fixed') !important;
}
.CookiesSelectMenu{
  backdrop-filter: v-bind('Theme.BackBlur');
  position: absolute;
  top: 100%;
  /* min-height: 1.1em; */
  width: 90%;
  background: v-bind('Theme.BG2');
  border-radius: 0px 0px var(--rad2) var(--rad2);
  display: none;
  border-top: solid 1px var(--shade2);
  z-index: 15;
  inset-inline-start: 5%;
}
.CookiesSelectItem{
  font-size: 0.9em;
  animation-name: Animation1;
  animation-duration: 0.5s;
  /* height: 1.5em; */
  overflow: hidden;
  /* transition-property: height;
  transition-duration: 0.4s; */
  /* margin: auto; */
  padding: 0.7em 1.2em;
  margin: 0.5em;
  margin-inline-start: 10px;
  border-radius: var(--rad3);
  display: flex;
}
.CookiesSelectRow{
  display: flex;
  width: 100%;
}
.CookiesSelectItem:hover{
  /* font-size: 1.2em; */
  background: v-bind('Theme.BG3');
  /* border-radius: var(--rad2); */
}
.DarkHover:hover{
  background: var(--shade4) !important;
}
.DarkHover{
  color: var(--colorw1)
}
@keyframes Animation1 {
  from { opacity: 0;}
  to { opacity: 1;}
}

.DropDownDisabled{
  pointer-events: none;
  opacity: 0.6;
}
.CookiesDropDownIndicator{
  width: 1em;
  position: absolute;
  inset-inline-end: 1em;
  transition-property: transform;
  transition-duration: 0.7s;
  transform-origin: 0.5em 0.2em;
  opacity: 0.4;
  margin-top: 0.5em;
}
.ImageList{
  max-height: 85%;
  float: inline-end;
  margin: 0em 0.8em;
}
.IconsList{
  max-height: 85%;
  float: inline-end;
  margin: 0em 0.8em;
}
.SelectedIcon{
  display: inline-flex;
  margin: 0em 0.8em;
  height: 100%;
}
.Icons{
  width: 1.5em;
  height: 1.5em;
}
</style>
