
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ReadCustomers()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, this.Filter)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<any>,
      Customers: [] as any[],
      MappedCustomers: {} as any,
      Profiles: [] as Array<Profile>
    }
  },
  computed: {
    Filter () :FilterObject {
      return {
        Lists: [
          {
            Name: 'Customers',
            Options: this.Customers,
            Res: 'SalesOrderFilter',
            Selectors: [['Name'], ['ID']],
            Visible: true,
            DataPath: ['CustomerID'],
            ListPath: ['ID']
          }
        ]
      }
    },
    Buttons () :Array<FunctionalTableButton> {
      return []
    },
    TableSettings () :FunctionalTable {
      return {
        DefaultSort: { Direction: -1, Path: 'ID' },
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'DateTime',
            Name: 'Date',
            Path: 'CustomDate',
            Sort: 'CustomDate'
          },
          {
            Type: 'Normal',
            Name: 'Serial',
            Bold: true,
            Path: ['CustomerID'],
            Sort: ['CustomerID'],
            Hash: '#',
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['CustomerName'],
            Sort: ['CustomerName'],
            Bold: true,
            IsSearchable: true,
            Class: 'Pointer',
            Emits: 'OpenVendor'
          },
          {
            Type: 'Normal',
            Name: 'Notes',
            Path: ['Description'],
            Sort: ['Description'],
            MultiLine: true,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Discount',
            Path: ['paymentDiscountValue'],
            Bold: true,
            Sort: ['paymentDiscountValue'],
            Class: 'colorr1',
            Unit: this.PriceUnit,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Value',
            Path: ['Value'],
            Bold: true,
            Sort: ['Value'],
            Class: 'F1_25em',
            Unit: this.PriceUnit,
            IsSearchable: true
          },
          {
            Type: 'StatusBox',
            Name: 'Type',
            Path: ['Type'],
            Sort: ['Type'],
            StatusClassColors: {
              Pay: 'BGRed',
              Collect: 'BGMain'
            }
          },
          {
            Name: 'Attachment',
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Clone',
                Visible: true,
                TooltTip: this.Translate('Open'),
                DynamicVisiblity: {
                  FixedValue: '',
                  Operator: 'NOT EQUAL',
                  ValuePath: ['Invoice']
                },
                Link: ['Invoice']
              }
            ]
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Edit',
                Visible: this.Access('Finance_Transactions_Edit'),
                TooltTip: this.Translate('Edit'),
                Emits: 'EditBranch',
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: false,
                  ValuePath: 'IsNew'
                }
              },
              {
                ID: 'Edit',
                Visible: this.Access('Finance_Transactions_Create'),
                TooltTip: this.Translate('Edit'),
                Emits: 'EditBranch',
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: true,
                  ValuePath: 'IsNew'
                }
              },
              {
                ID: 'Delete',
                Emits: 'DeleteBranch',
                Visible: this.Access('Finance_Transactions_Remove'),
                TooltTip: this.Translate('Remove'),
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: false,
                  ValuePath: 'IsNew'
                }
              },
              {
                ID: 'Delete',
                Emits: 'DeleteBranch',
                Visible: this.Access('Finance_Transactions_Create'),
                TooltTip: this.Translate('Remove'),
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: true,
                  ValuePath: 'IsNew'
                }
              }
            ]
          }
        ]
      }
    },
    ModifiedData () {
      var DateSpan = new Date(new Date(this.MEMRead('CurrentNetTime')).getTime() - (60 * 60 * 24 * 1000))
      return this.CookiesData.map((value) => { return { ...value, CustomerName: this.MappedCustomers['c' + value.CustomerID?.toString()], IsNew: new Date(value.AddedDate) > DateSpan } })
    },
    FilteredData () {
      return this.CookiesFilter(this.ModifiedData, this.Filter, this.Search)
    }
  },
  methods: {
    ReadCookiesData () {
      fetch('https://timeapi.io/api/Time/current/zone?timeZone=Europe/Amsterdam').then(response => response.json())
        .then(data => {
          // Handle the data received from the API
          this.MEMWrite('CurrentNetTime', data?.dateTime)
        })
        .catch(error => {
          // Handle any errors that occurred during the fetch
          console.error(error)
        })
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Finance_Transactions', '?Type=Collect').then(response => {
        this.CookiesData = response.map((value: any) => { return { ...value, Collected: value.Value + value.Discount } })
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCustomers () {
      this.Loading.push('Loading')
      this.Get('CRM_Customers').then(response => {
        this.Customers = this.GeneralSort(response.map((value: any) => { return { ...value, ID: value.ID.toString(), Serial: '#' + value.ID } }), 'ID', -1)
        this.Customers.forEach((value, index, array) => { this.MappedCustomers['c' + value.ID] = value.Name })
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditBranch (Trnsaction: any) {
      if (!this.Access('Finance_Transactions_Edit')) {
        return
      }
      this.OpenDialog('Finance_Transaction_Dialog', this.Translate('Edit Transaction') + ' (' + Trnsaction.ID + ')', { Data: { ...Trnsaction, CustomerType: Trnsaction.Type === 'b' ? 'v' : 'c' }, Editing: true }, {})
    },
    DeleteBranch (Branch: any) {
      if (!this.Access('Finance_Transactions_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Finance_Transactions', AddQuery: '?ID=' + Branch.ID, Text: 'Are you sure you want to delete <strong> Payment #' + Branch.ID + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.BranchDeleted, AfterDialogFunctionValue: Branch.Invoice } as GeneralAcceptDialogData, {})
    },
    BranchDeleted (fileURL :string) {
      if (fileURL) this.DeleteImage(fileURL)
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewPayment':
          this.OpenDialog('Finance_Transaction_Dialog', this.Translate('New Transaction'), { Data: { Type: 'Collect', CustomerType: 'c' } })
          break
        case 'NewBill':
          this.OpenDialog('Finance_Transaction_Dialog', this.Translate('New Transaction'), { Data: { Type: 'Pay', CustomerType: 'v' } })
          break
      }
    },
    ViewLocation (Branch :Branch) {
      window.open('https://www.google.com/maps/@' + Branch.GPSLongitude + ',' + Branch.GPSLatitude)
    },
    Whatsapp (customer: any) {
      this.OpenWhatsapp(customer.PhoneNumber)
    },
    OpenVendor (Transaction :any) {
      this.OpenDialog('Purchase_VendorView_Dialog', this.Translate('View ' + (Transaction.Type === 'Pay' ? 'Vendor' : 'Customer')), { Data: { VendorID: Transaction.CustomerID } })
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
        this.ReadCustomers()
      }
    },
    Filter: function (newVal, oldVal) { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
