
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, this.Filter)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<Catalog>
    }
  },
  computed: {
    Filter () :FilterObject | undefined {
      return undefined
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: this.Translate('New Catalog'),
          this: this,
          Function: 'NewCatalog',
          Icon: 'Add',
          Visible: this.Access('Catalogue_Create')
        },
        {
          Name: this.Translate('View Catalog'),
          this: this,
          Function: 'ViewCatalogs',
          Icon: 'OpenLink',
          IconColor: { ColorA: 'rgba(0,0,0,0.5)' },
          Visible: this.Access('Catalogue_Read')
        },
        {
          Name: this.Translate('Edit Cover'),
          this: this,
          Function: 'EditCover',
          Icon: 'Edited',
          IconColor: { ColorA: 'rgba(0,0,0,0.5)' },
          Visible: this.Access('Catalogue_Read')
        },
        {
          Name: this.Translate('Send'),
          this: this,
          Function: 'SendWhats',
          Icon: 'Whatsapp',
          Visible: this.Access('Integration_Whatsapp_SendMessage')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Items',
            Path: ['ItemsCount'],
            Sort: ['ItemsCount']
          },
          {
            Type: 'StatusBox',
            Path: 'visibility',
            Name: this.Translate('visibility'),
            StatusClassColors: { Visible: 'BGGreen', Hidden: 'BGRed' },
            Emits: 'ChangeHiddenState',
            Class: 'HoverGlow',
            Bold: true
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Whatsapp',
                Visible: this.Access('Integration_Whatsapp_SendMessage'),
                TooltTip: this.Translate('Send on WhatsApp'),
                Emits: 'SendWhatsapp'
              },
              {
                ID: 'Link',
                Visible: this.Access('Catalogue_Read'),
                TooltTip: this.Translate('Copy Link'),
                Emits: 'CopyLink'
              },
              {
                ID: 'OpenLink',
                Visible: this.Access('Catalogue_Read'),
                TooltTip: this.Translate('Open Catalog'),
                Link: 'CatalogLink'
              },
              {
                ID: 'Edit',
                Visible: this.Access('Catalogue_Edit'),
                TooltTip: this.Translate('Edit'),
                Emits: 'EditCatalog'
              },
              {
                ID: 'Delete',
                Emits: 'DeleteCatalog',
                Visible: this.Access('Catalogue_Remove'),
                TooltTip: this.Translate('Delete')
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      this.CookiesData.map(catalog => {
        catalog.ItemsCount = this.DeepFetcher(catalog, ['Items', 'length'], 'N/A')
        catalog.CatalogLink = '/Catalogs/ViewCatalog/' + catalog.ID
        catalog.visibility = catalog.IsHidden ? 'Hidden' : 'Visible'
        return catalog
      })
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Catalogs').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Catalogs'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditCatalog (Catalog :Catalog) {
      if (!this.Access('Catalogue_Edit')) {
        return
      }
      this.OpenDialog('Catalog_Dialog', 'Edit Catalog(' + Catalog.Name + ')', { Data: Catalog, Editing: true }, {})
    },
    CopyLink (Catalog :Catalog) {
      const elem = document.createElement('textarea')
      elem.value = location.origin + Catalog.CatalogLink
      document.body.appendChild(elem)
      elem.select()
      document.execCommand('copy')
      document.body.removeChild(elem)
      this.PushNotification('Link Copied')
    },
    OpenCatalog (Catalog :Catalog) {
      this.$router.push('/Catalogs/ViewCatalog/' + Catalog.ID)
    },
    DeleteCatalog (Catalog :Catalog) {
      if (!this.Access('Catalogue_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Catalogs', AddQuery: '?ID=' + Catalog.ID, Text: 'Are you sure you want to delete <strong>' + Catalog.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewCatalog':
          if (this.CookiesData.length >= 10) {
            this.OpenDialog('General_Accept_Dialog', this.Translate('Limits Reached!'), { ButtonClass: 'D-None', ButtonStyle: 'display: none;', PostAPI: '', Icon: 'Close', Text: this.Translate('Sorry! you have reached yur catalogs limits (10/10)') })
          } else {
            this.OpenDialog('Catalog_Dialog', this.Translate('New Catalog'), {})
          }
          break
        case 'ViewCatalogs':
          this.$router.push('/catalog/all')
          break
        case 'EditCover':
          this.$router.push('/Catalogs/ViewCatalogs')
          break
        case 'SendWhats':
          if (this.$store.state.CookiesConfig.WhatsappStatus === 'true') {
            var message = {
              messaging_product: 'whatsapp',
              to: '',
              type: 'template',
              template: {
                name: 'viewsaedyecatalogs',
                language: {
                  code: 'en'
                },
                components: [
                  {
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: this.$store.state.CookiesConfig.HeroImage
                        }
                      }
                    ]
                  },
                  {
                    type: 'body',
                    parameters: [
                      {
                        type: 'text',
                        text: this.$store.state.CookiesConfig.WhatsappContact
                      }
                    ]
                  }
                ]
              }
            }
            this.OpenDialog('Integration_WhatsappMessage_Dialog', 'Send Message on WhatsApp', { Data: message })
          } else {
            this.PushNotification(this.Translate('WhatsApp Integration not Verified!!'))
          }
          break
      }
    },
    SendWhatsapp (catalog :Catalog) {
      if (this.$store.state.CookiesConfig.WhatsappStatus === 'true') {
        var CatalogName = catalog.Name.trim().replace(/(\r\n|\n|\r)/gm, '').replace(/(\r\n|\n|\r)/, '').replaceAll('\r', '').replaceAll('\n', '').replaceAll('<br>', '').replaceAll('<br/>', '').trim()
        CatalogName = CatalogName.replace(/(\r\n|\n|\r)/gm, '<1br />')
        var re1 = /<1br \/><1br \/>/gi
        // var re1a = /<1br \/><1br \/><1br \/>/gi
        CatalogName = CatalogName.replace(re1, ' ')
        var re2 = /\\<1br \/>/gi
        CatalogName = CatalogName.replace(re2, ' ')
        var re3 = /\s+/g
        CatalogName = CatalogName.replace(re3, ' ')
        var re4 = /<2br \/>/gi
        CatalogName = CatalogName.replace(re4, '\n\n')
        var message = {
          messaging_product: 'whatsapp',
          to: '',
          type: 'template',
          template: {
            name: 'dynamic_catalog',
            language: {
              code: 'en'
            },
            components: [
              {
                type: 'header',
                parameters: [
                  {
                    type: 'image',
                    image: {
                      link: catalog.ImgUrl
                    }
                  }
                ]
              },
              {
                type: 'body',
                parameters: [
                  {
                    type: 'text',
                    text: CatalogName
                  },
                  {
                    type: 'text',
                    text: this.$store.state.CookiesConfig.WhatsappContact
                  }
                ]
              },
              {
                type: 'button',
                sub_type: 'URL',
                index: '0',
                parameters: [
                  {
                    type: 'payload',
                    payload: catalog.ID
                  }
                ]
              }
            ]
          }
        }
        this.OpenDialog('Integration_WhatsappMessage_Dialog', 'Send Message on WhatsApp', { Data: message })
      } else {
        this.PushNotification(this.Translate('WhatsApp Integration not Verified!!'))
      }
    },
    HideUnhideCatalog (catalog :Catalog) {
      var Patch = [{ op: 'replace', path: '/IsHidden', value: !catalog.IsHidden }]
      this.Post('PATCH', 'Catalogs', Patch, '?id=' + catalog.ID).then(response => {
        this.ReadCookiesData()
      }, error => {
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Filter: function () { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function () { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
