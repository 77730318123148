<template>
<div class="H-100 W-100 D-Flex" v-if="Loading">
  <div class="M-Auto">
    <CookiesLoading></CookiesLoading>
  </div>
</div>
<div class="H-100 W-100 D-Flex" v-if="Orders.length === 0">
  <div class="M-Auto">
    <span>{{LanguageSelector('No Orders', 'لا يوجد طلبات')}}</span>
  </div>
</div>
<apexchart v-else class="Color1" height="100%"
  type="donut"
  :options="chartOptions"
  :series="DriversArrays.Orders">
</apexchart>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'

export default {
  created () {
    this.ReadOrders()
    this.ReadDrivers()
  },
  data () {
    return {
      Orders: [],
      Drivers: []
    }
  },
  props: {},
  methods: {
    ReadOrders () {
      this.Loading = true
      this.Get('DoneOrders?' + this.DateAPIURL).then(response => {
        if (response !== null & response !== undefined) {
          this.Orders = response
        }
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Orders'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadDrivers () {
      this.Get('Employee/getdriver').then(response => {
        this.Drivers = response
      }, error => {
        error.CookiesError = 'Error in reading Drivers'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  components: {
    apexchart: VueApexCharts
  },
  computed: {
    DriversArrays () {
      const _this = this
      var AllOrders = this.Orders
      var BigArray = this.Drivers.map(function (item) {
        var DriverOrders = AllOrders.filter(function (order) {
          return _this.DeepFetcher(order, ['DelivardBy', 'DriverID']) === item.Id
        })
        return [item.Employee.Name, item.ID, DriverOrders.length]
      })
      this.Console('BigArray')
      this.Console(BigArray)
      var Names = this.AttributeArray(BigArray, [0])
      var IDs = this.AttributeArray(BigArray, [1])
      var Orders = this.AttributeArray(BigArray, [2])
      return {
        Names: Names,
        IDs: IDs,
        Orders: Orders
      }
    },
    chartOptions () {
      return {
        chart: {
          foreColor: this.Theme.color3,
          id: 'vuechart-example'
        },
        colors: this.$store.state.CookiesChart.colors,
        labels: this.DriversArrays.Names,
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            donut: {
              size: '70%',
              labels: {
                show: true,
                value: {
                  formatter: function (y) {
                    return y
                  }
                }
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 1,
            opacityTo: 1,
            shade: 'dark',
            stops: [50, 80, 100]
          }
        },
        legend: {
          show: true,
          position: 'left',
          floating: false,
          useSeriesColors: true,
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: 'Roboto, Helvetica, Arial',
          formatter: function (seriesName, opts) {
            var ChartData = opts.w.globals.series
            var DataTotal = 0
            ChartData.forEach(element => {
              DataTotal = DataTotal + element
            })
            return (opts.w.globals.series[opts.seriesIndex] / DataTotal * 100).toFixed(1) + '%' + ' ' + seriesName
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      }
    }
  }
}
</script>

<style scoped>
.CookiesCardFill{
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  display: flex;
}

.CookiesChart{
  /* flex-direction: column;
  flex: 1;
  display: flex; */
  height: 100%;
  display: flex;
  flex: auto;
}
.vue-apexcharts{
  margin: auto;
}
</style>
