
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
import VueApexCharts from 'vue3-apexcharts'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    CookiesData: { default: () => { return {} }, type: Object as PropType<DonutCardData> },
    CookiesSettings: { default: () => { return {} }, type: Object as PropType<DonutCardSettings> },
    Loading: { default: false, type: Boolean }
  },
  emits: ['Clicked'],
  data () {
    return {
      LocalLoading: false
    }
  },
  methods: {
    clickHandler (event :any, chartContext :any, config :any) {
      this.$emit('Clicked', this.CookiesData.Data[this.Series[config.dataPointIndex]])
    }
  },
  components: {
    apexchart: VueApexCharts
  },
  computed: {
    Series () {
      try {
        return this.CookiesData.Data.map((element :any) => {
          return this.DeepFetcher(element, this.CookiesData.SeriesPath) ? this.DeepFetcher(element, this.CookiesData.SeriesPath) : ''
        })
      } catch (error) {
        return []
      }
    },
    Labels () {
      try {
        return this.CookiesData.Data.map((element :any) => {
          return this.DeepFetcher(element, this.CookiesData.LabelsPath) ? this.DeepFetcher(element, this.CookiesData.LabelsPath) : ''
        })
      } catch (error) {
        return []
      }
    },
    chartOptions () {
      const _this = this
      var Series = _this.Series
      var DataTotal = 0
      Series.forEach((element :any) => {
        DataTotal = DataTotal + element
      })
      return {
        chart: {
          animations: {
            enabled: true,
            easing: 'easeinout',
            animateGradually: {
              enabled: true,
              delay: 200
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
          foreColor: this.Theme.color3,
          id: 'vuecharssst-example',
          // type: 'polarArea',
          type: 'donut'
        },
        colors: this.CookiesSettings.Colors ? this.CookiesSettings.Colors : this.$store.state.CookiesChart.colors,
        labels: this.Labels,
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: false,
            donut: {
              size: '70%',
              labels: {
                show: true,
                value: {
                  formatter: function (value :number) {
                    if (typeof value === 'number') {
                      return _this.DecimalFixer(value / DataTotal * 100, 1) + '%'
                    } else {
                      return _this.DecimalFixer(parseFloat(value) / DataTotal * 100, 1) + '%'
                    }
                  },
                  fontSize: '1.4em',
                  fontWeight: 'bold'
                },
                name: {
                  formatter: function (seriesName :string) {
                    return seriesName
                  },
                  fontSize: '1em',
                  fontWeight: 'bold'
                }
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 1,
            opacityTo: 1,
            shade: 'dark',
            stops: [50, 80, 100]
          }
        },
        legend: {
          show: true,
          markers: {
            width: 20,
            radius: 3
          },
          fontSize: '12px',
          position: 'left',
          floating: false,
          useSeriesColors: true,
          horizontalAlign: 'right',
          formatter: function (seriesName :string, opts :any) {
            return (_this.Series[opts.seriesIndex] / DataTotal * 100).toFixed(1) + '%' + ' ' + seriesName
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: false
        },
        tooltip: {
          enabled: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w } :any) {
            var Color = _this.Theme.BG5Fixed
            return `<div style="color: ${Color}; gap: 0.5em; justify-content: center;" class="BGColor3 P_75em BGBlur Shadow1 F_85em D-Flex FlexWrap">` +
              `<div style="background: ${w.config.colors[seriesIndex]};" class="SQR1em Rad1"></div>
              <div>
                <span>${series[seriesIndex]}</span>
              <br>
              <span>${_this.Labels[seriesIndex]}</span>
                </div>
              ` +
              '</div>'
          }
        }
      }
    }
  },
  watch: {}
})

